import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { MdPhoneIphone } from 'react-icons/md';
// import API, { getOrderDetails } from './../../api/api_calls';
// import API, { getOrderDetails } from './../../api/api_calls';
import { Helmet } from 'react-helmet';
import Layout from '../../Layouts/StoreLayout';
import { useLocation } from 'react-router';


const OrderTracking = ({ ws,}) => {

	const { orderId } = useParams();
	// const [order, setOrder] = useState('');
	const [active, setActive] = useState('history');
	const [underlineStyle, setUnderlineStyle] = useState({});
	const location = useLocation();
	const { storeData } = location.state || {};
	console.log(storeData)

	

	const handleSetActive = (tabName) => {
		setActive(tabName);
		// Calculate the left position of the underline based on the clicked tab
		const tabElement = document.getElementById(tabName);
		const left = tabElement.offsetLeft;
		// Set the new underline style
		setUnderlineStyle({ left, transition: 'left 0.3s ease' });
	};

	const handleGetOrderDetails = async () => {
		// const order = await getOrderDetails(orderId);
		// console.log(order.order);
		// setOrder(order.order);
	};
	useEffect(() => {
		handleGetOrderDetails();
	}, []);

	useEffect(() => {
		if (!ws) return;

		// Event listener for incoming WebSocket messages
		ws.onmessage = (event) => {
			const eventData = JSON.parse(event.data);
			const eventType = eventData.event; // Extract event type
			console.log(eventType);
			const payload = eventData.payload; // Extract payload
			console.log(payload);
			handleEventType(eventType, payload);
		};
		// Cleanup function
		return () => {
			ws.onmessage = null;
		};
	}, [ws]); // Re-run effect when ws changes
	const handleEventType = (eventType, payload) => {
		switch (eventType) {
			case 'picked':
				handlePickedEvent(eventType, payload);
				break;
			case 'chargeSuccess':
				handleChargeSuccessEvent(eventType, payload);
				break;
			case 'assigned':
				handleAssignedEvent(eventType, payload);
				break;
			case 'delivered':
				handleDeliveredEvent(eventType, payload);
				break;
			case 'return':
				handleReturnEvent(eventType, payload);
				break;
			default:
				console.log(`Unhandled event type: ${eventType}`);
		}
	};
	const handlePickedEvent = (eventType, payload) => {
		// Handle "picked" event
		console.log(`Received ${eventType} event:`, payload);
		handleGetOrderDetails();
	};
	const handleChargeSuccessEvent = (eventType, payload) => {
		// Handle "chargeSuccess" event
		console.log(`Received ${eventType} event:`, payload);
		handleGetOrderDetails();
	};
	const handleAssignedEvent = (eventType, payload) => {
		// Handle "chargeSuccess" event
		console.log(`Received ${eventType} event:`, payload);
		handleGetOrderDetails();
	};
	const handleDeliveredEvent = (eventType, payload) => {
		// Handle "chargeSuccess" event
		console.log(`Received ${eventType} event:`, payload);

		handleGetOrderDetails();
	};
	const handleReturnEvent = (eventType, payload) => {
		// Handle "chargeSuccess" event
		console.log(`Received ${eventType} event:`, payload);
		handleGetOrderDetails();
	};

	const formatServerClock = (dateTimeString) => {
		const options = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			hour12: true,
		};

		const formattedDate = new Date(dateTimeString).toLocaleString(
			'en-US',
			options,
		);
		return formattedDate;
	};
	const [order, setOrder] = useState(null); // Initially set to null
	const formatDate = (timestamp) => {
		const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
		return new Date(timestamp).toLocaleString('en-US', options);
	  };
	// Simulating an order fetch (use actual API fetch in real scenario)
	useEffect(() => {
	  setTimeout(() => {
		setOrder({
		  id: '53R83-14gdgsk',
		  status: 'Order Delivered', // Can be "In Progress", "Completed", or "Delivered"
		  tracking: [
			{ event: 'Order Created', timestamp: '13th Oct. 16:00' },
			{ event: 'Order Ready', timestamp: '13th Oct. 16:00' },
			{ event: 'Order Pending', timestamp: '13th Oct. 16:00' },
			{ event: 'Order Accepted', timestamp: '13th Oct. 16:00' },
			{ event: 'Order Picked Up', timestamp: '13th Oct. 16:00' },
			{ event: 'Order Out for Delivery', timestamp: '13th Oct. 16:00' },
			{ event: 'Order in Transit', timestamp: '13th Oct. 16:00' },
			{ event: 'Order Delivered', timestamp: '13th Oct. 16:00' },
		  ],
		  courier: {
			name: 'Bode Sunday',
			phone_number: '+1234567890',
		  },
		  store: 'Deevo Technologies',
		  from: 'Dugbe, Ibadan, Oyo State',
		  destination: '21 Uloho Avenue, behind Tozak hotel, Ughelli, Delta State',
		  orderNumber: '12092',
		});
	  }, 1000); // Simulate an API call delay of 1 second
	}, []);
	// useEffect(() => {
	// 	document.title = 'Ridefraser Central | ' + order?.merchant?.store?.store_name +" "+ orderId;
	// }, []);
	
	
	  if (!order) {
		return <div className="text-center text-gray-500">Loading order details...</div>;
	  }
	return (
		

		
		<Layout storeName={storeData.storeName} storeData={storeData}>
{/* {storeData.storeName} */}

{/* <div className="min-h-scree flex justify-center items-center p-6"> */}
      <div className="w-full max-w-6xl bg-white  p-6 grid grid-cols-12 gap-6 mx-auto my-8">
        {/* Left section: Order Tracking */}
        <div className="col-span-8">
          {/* Order Tracking Header */}
          <div className="flex justify-between items-center border-b pb-4 mb-6">
            <div>
              <p className="text-sm text-gray-400">Tracking ID: {order.id}</p>
              <p className="font-semibold text-lg">Order {order.orderNumber}</p>
            </div>
            <div className="text-green-500 font-semibold">
              {order.status}
            </div>
          </div>

          {/* Tracking Timeline */}
          <div className="space-y-8">
            {order.tracking.map((track, index) => (
              <div key={index} className="flex items-start space-x-4">
                {/* Timeline marker */}
                <div className="flex flex-col items-center">
                  <div className={`h-3 w-3 rounded-full ${index === order.tracking.length - 1 ? 'border-2 border-green-500 bg-white' : 'bg-green-500'}`}></div>
                  {index < order.tracking.length - 1 && <div className="h-12 w-0.5 bg-gray-300"></div>}
                </div>

                {/* Tracking event details */}
                <div>
                  <p className="font-semibold">{track.event}</p>
                  <p className="text-sm text-gray-500">{track.timestamp}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Right section: Order Details */}
        <div className="col-span-4 border-l pl-6">
          {/* Store Info */}
          <p className="text-sm text-gray-400">Store</p>
          <p className="font-semibold mb-4">{order.store}</p>

          {/* From & Destination */}
          <p className="text-sm text-gray-400">From</p>
          <p className="mb-4">{order.from}</p>

          <p className="text-sm text-gray-400">Destination</p>
          <p className="mb-8">{order.destination}</p>

          {/* Courier Info */}
          <div className="flex items-center space-x-3 mb-4">
            <img src="https://via.placeholder.com/40" alt="Courier" className="w-10 h-10 rounded-full object-cover" />
            <div>
              <p className="font-semibold">{order.courier.name}</p>
              <p className="text-sm text-gray-500">Courier</p>
            </div>
          </div>

          <button
            onClick={() => window.open(`tel:${order.courier.phone_number}`)}
            className="flex items-center justify-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-500 w-full"
          >
            <MdPhoneIphone size={20} className="mr-2" />
            Call Courier
          </button>
        </div>
      </div>
    {/* </div> */}
		</Layout>
	);
};

export default OrderTracking;

