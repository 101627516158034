export const paths = {
	HOME: '/',
	ABOUT: '/about',
	COMMUNITY: '/community',
	BLOG: '/blog',
	BLOG_DETAIL: '/blog/:id',
	DASHBOARD: '/dashboard',
	PRIVACY_POLICY: '/privacy-policy',
	ADMIN: '/admin',
	EVENTS: '/events',

	// shopping
	STORE: '/:storeIdentifier',
	PRODUCT_DETAILS: '/:storeIdentifier/:categorySlug/:productId',
	CHECKOUT: 'checkout',
	ORDER_TRACKING: '/:storeIdentifier/order-tracking',
	// ORDER_TRACKING:'/:storeIdentifier/cart',
};
