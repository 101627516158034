import React, { useEffect, useState } from 'react';
import Button from './button';
import { FaMinus, FaPlus, FaTimes } from 'react-icons/fa';
import { useAPI } from '../../../api/api_context';
import { toast } from 'sonner';
import { useDispatch } from 'react-redux';
import { setCart } from '../../../store/cartSlice';

const ProductModal = ({
	product,
	onClose,

	onAddToCartSuccess,
}) => {
	const apiservice = useAPI();
	const [selectedColor, setSelectedColor] = useState(null);
	const [selectedSize, setSelectedSize] = useState(null);

	const [selectedImage, setSelectedImage] = useState(null);

	const [colorNames, setColorNames] = useState({});
	const [quantity, setQuantity] = useState(1);
	const [selectedAttributes, setSelectedAttributes] = useState({
		color: null,
		size: null,
	});
	const dispatch = useDispatch();

	const fetchColorName = async (colorHex) => {
		try {
			const response = await fetch(
				`https://api.color.pizza/v1/?values=${encodeURIComponent(
					colorHex.replace('#', ''),
				)}`,
			);
			const data = await response.json();
			const colorData = data.colors[0];
			setColorNames((prevColorNames) => ({
				...prevColorNames,
				[colorHex]: colorData.name,
			}));
		} catch (error) {
			console.error('Error fetching color name:', error);
		}
	};
	useEffect(() => {
		if (selectedColor && selectedColor.startsWith('#')) {
			if (colorNames[selectedColor]) {
				// setSelectedColorName(colorNames[selectedColor]);
			} else {
				fetchColorName(selectedColor);
			}
		}
	}, [selectedColor, colorNames]);

	useEffect(() => {
		if (product?.attributes) {
			const { type, clothing, footwear } = product.attributes;

			let colors = [];
			if (type === 'Clothing' && clothing?.colors) {
				colors = clothing.colors;
			} else if (type === 'Footwear' && footwear?.colors) {
				colors =
					typeof footwear.colors === 'string'
						? footwear.colors
								.split(',')
								.map((color) => color.trim())
						: footwear.colors;
			}

			colors.forEach((color) => {
				if (!colorNames[color] && color.startsWith('#')) {
					fetchColorName(color);
				}
			});
		}
	}, [product, colorNames]);

	const handleColorSelect = (color) => {
		setSelectedColor(color);
		const colorName = colorNames[color] || 'Loading...';
		// setSelectedColorName(colorName);

		// Update attributes with new color
		setSelectedAttributes((prev) => ({
			...prev,
			color: colorName,
		}));
	};

	const handleSizeSelect = (size) => {
		setSelectedSize(size);

		// Update attributes with new size
		setSelectedAttributes((prev) => ({
			...prev,
			size: size,
		}));
	};

	const handleAddToCart = async () => {
		const clothingAttributes = product.attributes?.clothing || {};
		const footwearAttributes = product.attributes?.footwear || {};
	
		// Determine which attributes to use (e.g., prioritize clothing, then footwear)
		const activeAttributes = clothingAttributes.colors?.length > 0 || clothingAttributes.sizes?.length > 0
			? clothingAttributes
			: footwearAttributes;
	
		// Check if the product has color and size attributes
		const hasColors = Array.isArray(activeAttributes?.colors) && activeAttributes.colors.length > 0;
		const hasSizes = Array.isArray(activeAttributes?.sizes) && activeAttributes.sizes.length > 0;
	
	console.log({ hasColors, hasSizes, selectedColor, selectedSize });
	if ((hasColors && !selectedColor) || (hasSizes && !selectedSize)) {
        return toast('Please select a color and size.');
    }
		try {
			const attributes = {
				color: selectedColor,
				size: selectedSize,
			};

			const response = await apiservice.addToCart(
				product._id,
				quantity,
				attributes,
			);
			console.log(response.data);
			if (response.status === 200) {
				// console.log(response.status);
				toast('Added to cart successfully');
				console.log(response);
				dispatch(
					setCart({
						products: response.data.cart.products,
						totalCartValue: response.data.cart.totalCartValue,
						serviceCharge: response.data.cart.serviceCharge,
						_id: response.data.cart._id,
					}),
				);
			}
		} catch (error) {
			toast('Failed to add to cart');
		}
	};

	const handleIncrement = () => {
		const newQty = quantity + 1;
		setQuantity(newQty);
	};

	const handleDecrement = () => {
		if (quantity > 1) {
			const newQty = quantity - 1;
			setQuantity(newQty);
		}
	};

	useEffect(() => {
		if (product) {
			setSelectedImage(product.images[0]);
		}
	}, [product]);

	const handleImageClick = (image) => {
		setSelectedImage(image);
	};

	const renderSizes = (sizes) => {
		if (!sizes) return null;

		const sizeArray = Array.isArray(sizes)
			? sizes
			: sizes.split(',').map((size) => size.trim());

		return (
			<div className='w-full flex flex-wrap gap-3 text-[15px]'>
				{sizeArray.map((size) => (
					<div
						key={size}
						className={`bg-gray-100 px-6 py-1.5 rounded-full w-fit cursor-pointer ${
							selectedSize === size
								? 'bg-[#E6F7F7] border-[0.5px] border-[#28A745] text-[#28A745]'
								: ''
						}`}
						onClick={() => handleSizeSelect(size)}>
						{size}
					</div>
				))}
			</div>
		);
	};

	const renderColors = (colors) => {
		if (!colors) return null;

		const colorArray = Array.isArray(colors)
			? colors
			: colors.split(',').map((color) => color.trim());

		return (
			<div className='w-full flex flex-wrap gap-3 text-[15px]'>
				{colorArray.map((color) => (
					<div
						key={color}
						className={`flex items-center gap-2 bg-gray-100 pr-4 pl-1.5 py-1.5 rounded-full w-fit cursor-pointer ${
							selectedColor === color
								? 'bg-[#E6F7F7] border-[0.5px] border-[#28A745] text-[#28A745]'
								: ''
						}`}
						onClick={() => handleColorSelect(color)}>
						<div
							className={`h-6 w-6 rounded-full border ${
								selectedColor === color
									? 'border-[#28A745]'
									: ''
							}`}
							style={{
								backgroundColor: color.startsWith('#')
									? color
									: '#' + color,
							}}
						/>
						<div
							className={
								selectedColor === color ? 'text-[#28A745]' : ''
							}>
							{color.startsWith('#')
								? colorNames[color] || 'Loading...'
								: color}
						</div>
					</div>
				))}
			</div>
		);
	};

	const renderAttributes = () => {
		if (!product?.attributes) return null;

		const { type, clothing, footwear, electronics } = product.attributes;

		switch (type) {
			case 'Clothing':
				return (
					<div className='flex flex-col gap-5'>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px] font-light'>
								Available Sizes
							</div>
							<div className='text-primary'>
								{renderSizes(clothing.sizes)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px] font-light'>
								Available Colors
							</div>
							<div className='text-primary'>
								{renderColors(clothing.colors)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px] font-light'>
								Gender
							</div>
							<div className='text-primary'>
								{clothing.gender}
							</div>
						</div>
					</div>
				);

			case 'Footwear':
				return (
					<div className='flex flex-col gap-6'>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px]'>
								Available Sizes
							</div>
							<div className='text-primary'>
								{renderSizes(footwear.sizes)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px]'>
								Available Colors
							</div>
							<div className='text-primary'>
								{renderColors(footwear.colors)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px]'>
								Brand
							</div>
							<div className='text-primary'>{footwear.brand}</div>
						</div>
					</div>
				);

			case 'Electronics':
				return (
					<div className='flex flex-col gap-6'>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px]'>
								Type
							</div>
							<div className='text-primary'>
								{electronics.type}
							</div>
						</div>
						{electronics.type === 'laptop' && (
							<>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[13px]'>
										RAM
									</div>
									<div className='text-primary'>
										{electronics.laptops.ram} GB
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[13px]'>
										Processor
									</div>
									<div className='text-primary'>
										{electronics.laptops.processor}
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[13px]'>
										Storage
									</div>
									<div className='text-primary'>
										{electronics.laptops.storage} GB
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[13px]'>
										Operating System
									</div>
									<div className='text-primary'>
										{electronics.laptops.os}
									</div>
								</div>
							</>
						)}
					</div>
				);

			default:
				return null;
		}
	};

	if (!product) return null;

	return (
		<div
			className='fixed inset-0 bg-primary bg-opacity-90 flex md:items-center md:justify-center z-50 justify-end w-full md:w-full'
			onClick={onClose}>
			<div
				className='bg-white rounded-lg md:w-[50%]'
				onClick={(e) => e.stopPropagation()}>
				<div className='flex space-x-4 justify-between w-full items-center py-4 px-6'>
					<div className='text-[21px] font-semibold w-full leading-none'>
						{product.name}
					</div>

					<div className='flex space-x-4'>
						{/* <button
							onClick={() => {}}
							className=' text-gray-500 bg-formBG rounded-full p-2 '>
							<i className='fi fi-ss-share w-[18px] h-[18px] flex items-center'></i>
						</button> */}
						<button
							onClick={onClose}
							className=' text-gray-500 bg-formBG rounded-full p-2'>
							<FaTimes />
						</button>
					</div>
				</div>
				<div className='pt-0 p-6'>
					<div className='md:flex gap-6 w-full '>
						<div className='md:w-1/2'>
							<div className=' '>
								<img
									src={selectedImage}
									alt='Product'
									className='w-full h-[30vh] md:h-[45vh] object-cover rounded-md'
								/>
							</div>
							<div className='flex gap-4 overflow-x-auto py-2'>
								{product.images.map((image) => (
									<img
										key={image}
										src={image}
										alt='Thumbnail'
										onMouseEnter={() =>
											handleImageClick(image)
										}
										className={`w-20 h-20 object-cover cursor-pointer ${
											selectedImage === image
												? 'border-2 border-primary'
												: 'border'
										} rounded-md`}
									/>
								))}
							</div>
						</div>

						{/* Product Details */}
						<div className='md:w-1/2 space-y-4 relative'>
							<div className='pb-32 h-[47.5vh] space-y-4 overflow-y-auto'>
								<div className='space-y-1'>
									<p className='text-secondary text-[13px] font-light'>
										Price
									</p>
									<p className='text-primary'>
										NGN {product.unitPrice.toLocaleString()}
									</p>
								</div>
								<div className='space-y-1'>
									<p className='text-secondary text-[13px] font-light'>
										Description
									</p>
									<p className='text-primary font-light'>
										{product.description ||
											'No description available.'}
									</p>
								</div>

								{/* Colors */}
								{renderAttributes()}
							</div>

							<div className='absolute bottom-0 right-0 w-full bg-white'>
								{(selectedAttributes.color ||
									selectedAttributes.size) && (
									<div className='bg-neutral border border-outline/50 w-full mb-2 p-3 rounded-lg'>
										<p>
											{selectedAttributes.color &&
												`Color: ${selectedAttributes.color}`}
											{selectedAttributes.color &&
												selectedAttributes.size &&
												' | '}
											{selectedAttributes.size &&
												`Size: ${selectedAttributes.size}`}
										</p>
									</div>
								)}

								<div className='border-t pt-4 flex justify-between w-full items-center gap-4 '>
									<Button
										type='primary'
										label={`Add ${quantity} to Cart`}
										onClick={() => handleAddToCart()}
										className='rounded-full w-full text-[15px]'
										size='M'
									/>
									<div className='flex items-center gap-2'>
										<button
											onClick={handleDecrement}
											className='w-10 h-10 cursor-pointer bg-formBG flex items-center justify-center border rounded-full text-lg text-gray-700 hover:bg-gray-100 focus:outline-none'
											disabled={quantity <= 1}>
											<FaMinus />
										</button>

										<div
											onClick={handleIncrement}
											className='w-10 h-10 cursor-pointer bg-formBG flex items-center justify-center border rounded-full text-lg text-gray-700 hover:bg-gray-100 focus:outline-none'>
											<FaPlus />
										</div>
									</div>
								</div>
							</div>
							{/* <button className="mt-4 bg-gr text-white rounded-lg px-6 py-2">Add to Cart</button> */}

							{/* <p className='text-center text-basegreen mt-4'>
							View Details
						</p> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductModal;
