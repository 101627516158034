import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	sessionFail,
	sessionStart,
	sessionSuccess,
} from '../store/sessionSlice';
import { useAPI } from '../api/api_context';
import { LinearProgress, Box, Typography } from '@mui/material';
import { setStoreDetails } from '../store/storeSlice';
import ErrorModal from './StoreFront/components/ErrorModal';
import { useLocationService } from './StoreFront/utils/useLocationService';

const StoreContainer = () => {
	const dispatch = useDispatch();
	const apiService = useAPI();
	const { storeIdentifier } = useParams();

	const [storeData, setStoreData] = useState(null);
	const [loadingMessage, setLoadingMessage] = useState(
		'Initiating session...',
	);

	const {
		fetchLocation,
		error,
		isModalOpen,
		closeModal,
		retryFetchLocation,
	} = useLocationService(apiService.getCurrentUserLocation);

	const getStoreData = useCallback(async () => {
		setLoadingMessage('Getting store data...');
		try {
			const response = await apiService.getStore(storeIdentifier);
			setStoreData(response.store);
			dispatch(setStoreDetails(response.store));

			await fetchLocation();
		} catch (error) {
			console.error('Error fetching store data:', error);
		}
	}, [apiService, dispatch, fetchLocation, storeIdentifier]);

	const initiateSession = useCallback(async () => {
		dispatch(sessionStart());
		try {
			const sessionId = await apiService.initiateSession();
			dispatch(sessionSuccess({ sessionId }));
			await getStoreData();
		} catch (error) {
			dispatch(sessionFail(error.message));
			console.error('Error initiating session:', error);
		}
	}, [apiService, dispatch, getStoreData]);

	useEffect(() => {
		initiateSession();
	}, []);

	if (!storeData) {
		return (
			<Box
				display='flex'
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
				height='100vh'>
				<Typography
					variant='h6'
					gutterBottom>
					{loadingMessage}
				</Typography>
				<LinearProgress style={{ width: '50%' }} />
				<ErrorModal
					message={error}
					onClose={closeModal}
					onRetry={retryFetchLocation}
					open={isModalOpen}
				/>
			</Box>
		);
	}

	if (!storeData.storeName) {
		return (
			<Box
				display='flex'
				justifyContent='center'
				alignItems='center'
				height='100vh'>
				<p>Error: Store data could not be loaded.</p>
			</Box>
		);
	}

	return (
		<>
			<Outlet context={{ storeData }} />
			<ErrorModal
				message={error}
				onClose={closeModal}
				onRetry={retryFetchLocation}
				open={isModalOpen}
			/>
		</>
	);
};

export default StoreContainer;
