import React, { createContext, useContext, useState, useEffect } from 'react';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
	const [ws, setWs] = useState(null);

	useEffect(() => {
		const isTestEnv = process.env.REACT_APP_ENV === 'test';
		const wsProtocol =
			window.location.protocol === 'https:' ? 'wss://' : 'ws://';
		const host = isTestEnv
			? 'localhost:1222'
			: process.env.REACT_APP_CENTRAL_SERVER;

		const socket = new WebSocket(`${wsProtocol}${host}`);
		setWs(socket);

		socket.onopen = () => {
			console.log('WebSocket connection established');
		};

		socket.onclose = () => {
			console.log('WebSocket connection closed');
		};

		socket.onerror = (error) => {
			console.error('WebSocket error:', error);
		};

		return () => {
			if (socket) {
				socket.close();
			}
		};
	}, []);

	return (
		<WebSocketContext.Provider value={ws}>
			{children}
		</WebSocketContext.Provider>
	);
};

export const useWebSocket = () => {
	return useContext(WebSocketContext);
};
