import React from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa6';
import { numberWithCommas } from '../utils/add_commas';

const CartItem = ({
	product,
	handleDecrement,
	handleIncrement,
	removeProduct,
}) => {
	return (
		<div className='border-b py-6 px-4 w-full'>
			{/* Product Info */}
			<div className='flex justify-between items-center'>
				<div className='h-full justify-center flex flex-col space-y-[2px]'>
					<p className='font-medium'>{product.name}</p>
					<p className='text-secondary text-[13px]'>
						NGN {numberWithCommas(product.price * product.quantity)}
					</p>
				</div>
				<img
					src={product.images[0]}
					alt=''
					className='h-12 w-12 rounded-md object-cover'
				/>
			</div>

			{/* Quantity Controls */}
			<div className='flex items-center mt-4 justify-between'>
				<div className='flex items-center space-x-3'>
					<button
						onClick={() => handleDecrement(product)}
						className='w-6 h-6 cursor-pointer bg-formBG flex items-center justify-center border rounded-full text-gray-700 hover:bg-gray-100 focus:outline-none'>
						<FaMinus
							className='w-full flex items-center justify-center'
							size={14}
						/>
					</button>

					<div className='px-5 py-1.5 text-[13px] cursor-pointer bg-formBG flex items-center justify-center border rounded-md text-gray-700 leading-none hover:bg-gray-100 focus:outline-none'>
						{product.quantity}
					</div>
					<button
						onClick={() => handleIncrement(product)}
						className='w-6 h-6 cursor-pointer bg-formBG flex items-center justify-center border rounded-full text-gray-700 hover:bg-gray-100 focus:outline-none'>
						<FaPlus
							className='w-full flex items-center justify-center'
							size={14}
						/>
					</button>
				</div>
				<button
					className='underline text-[13px] text-basegreen'
					onClick={() => removeProduct(product)}>
					Remove
				</button>
			</div>
		</div>
	);
};

export default CartItem;
