import React, { useState } from 'react';
import Button from './button';
import ModalHeader from './modalHeader';

const ScheduleDelivery = ({
	setIsModalOpen,
	setIsOn,
	scheduledDate,
	setScheduledDate,
}) => {
	const [selectedTime, setSelectedTime] = useState(null);
	const [selectedDate, setSelectedDate] = useState('today');



	const handleCloseModal = () => {

		if (!selectedDate || !selectedTime ) {
			setIsOn(false);
		}
	
		// Close the modal
		setIsModalOpen(false);
	};
	

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const formatDate = (date) =>
    date.toLocaleDateString("en-US", { month: "short", day: "numeric" });

  const todayLabel = `Today ${formatDate(today)}`;
  const tomorrowLabel = `Tomorrow ${formatDate(tomorrow)}`;

	const generateTimeSlots = () => {
		const startHour = 7;
		const endHour = 21;
		const slots = [];
		const now = new Date();

    for (let hour = startHour; hour <= endHour; hour++) {
      const time = new Date(today);
      time.setHours(hour, 0, 0, 0);

	  if (selectedDate === "today" && time < now) continue;

      slots.push(
        time.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
      );
    }
    return slots;
  };

	const timeSlots = generateTimeSlots();

	const handleSelect = () => {
		if (selectedDate && selectedTime) {
			const dateObj = new Date(
				selectedDate === 'today' ? today : tomorrow,
			);

			// Parse the selected time (e.g., "7:00 AM")
			const timeParts = selectedTime.match(/(\d+):(\d+) (\w+)/);
			if (timeParts) {
				let [_, hour, minute, meridian] = timeParts;
				hour = parseInt(hour, 10);
				minute = parseInt(minute, 10);

				// Convert to 24-hour format
				if (meridian === 'PM' && hour !== 12) {
					hour += 12;
				} else if (meridian === 'AM' && hour === 12) {
					hour = 0;
				}

				dateObj.setHours(hour, minute, 0, 0);
			}

			// Pass the ISO string to the parent component
			setScheduledDate(dateObj.toISOString());
			handleCloseModal();
		}
	};

	return (
		<div
			className='fixed inset-0 bg-primary bg-opacity-50 z-50 flex items-center justify-center modal-overlay'
			onClick={handleCloseModal}>
			<div
				className='bg-white rounded-lg max-w-md w-full mx-4 relative'
				onClick={(e) => e.stopPropagation()}>
				<ModalHeader
					header='Schedule delivery'
					onClick={handleCloseModal}
				/>

        {/* Date Selection */}
        <div className="flex space-x-2 border-b p-4 text-[15px]">
          <button
            className={`px-4 py-2 rounded-full ${
              selectedDate === "today"
                ? "bg-primary text-white"
                : "border-[#AAB1AF] border text-[#AAB1AF]"
            }`}
            onClick={() => setSelectedDate("today")}
          >
            {todayLabel}
          </button>
          <button
            className={`px-4 py-2 rounded-full ${
              selectedDate === "tomorrow"
                ? "bg-primary text-white"
                : "border-[#AAB1AF] border text-[#AAB1AF]"
            }`}
            onClick={() => setSelectedDate("tomorrow")}
          >
            {tomorrowLabel}
          </button>
        </div>

				{/* Time Slots */}
				<div className='p-4 space-y-4'>
					<div className='flex flex-wrap gap-3 w-full max-h-[55vh] overflow-y-auto no-scrollbar scroll-smooth'>
						{timeSlots.map((time, index) => (
							<div
								key={index}
								onClick={() => setSelectedTime(time)}
								className={`px-[12px] py-[6px] text-[15px] rounded-full cursor-pointer border ${
									selectedTime === time
										? 'bg-neutral text-basegreen border-basegreen'
										: 'bg-grey text-secondary'
								}`}>
								{time}
							</div>
						))}
					</div>
					<div className='flex justify-center items-center'>
						<Button
							type='primary'
							label='Select'
							className='w-full rounded-full'
							borderRadius='rounded-[100px]'
							onClick={handleSelect}
							disabled={!selectedTime}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ScheduleDelivery;
