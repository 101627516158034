import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/Logo.png';

const Sidebar = () => {
	return (
		<div className='h-screen w-64 bg-white border-r'>
			<div className='flex items-center h-[88px] justify-center '>
				{/* <h1 className="text-3xl font-bold text-green-600">Ridefraser</h1> */}
				<img
					src={logo}
					alt='logo'
				/>
			</div>
			<nav className='mt-8'>
				<Link
					to='/dashboard'
					className='flex items-center py-2 px-8 text-gray-700 hover:bg-gray-200'>
					<span className='mx-4 font-medium'>Dashboard</span>
				</Link>
				<Link
					to='/events'
					className='flex items-center py-2 px-8 text-gray-700 hover:bg-gray-200'>
					<span className='mx-4 font-medium'>Events</span>
				</Link>
			</nav>
		</div>
	);
};

export default Sidebar;
