import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Avatar from "./avatar";
import x from "../../../assets/images/x2.svg";
import track from "../../../assets/images/track.svg";
import promo from "../../../assets/images/ticket-alt 1.svg";
import cart from "../../../assets/images/cart.svg";
import category from "../../../assets/images/category.svg";
import { cartOpen } from '../../../store/cartSlice';
import { useDispatch } from 'react-redux';

const Sidebar = ({ isOpen, onClose, storeData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOrderTrackingClick = () => {
    // Replace ':storeIdentifier' with the actual store identifier you need
    navigate("/estherstore/order-tracking", { state: { storeData } });
    // navigate(`/${storeData.storeIdentifier}/order-tracking?storeName=${storeData.storeName}`);
    onClose(); // Optionally close the sidebar after navigation
  };
const handleCartModal = () => {
  onClose();
  dispatch(cartOpen(true))

}
  return (
    <div
      className={`fixed top-0 z-50 left-0 h-screen w-64 bg-white shadow-lg transform py-8 px-6 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out`}
    >
      <div className="flex justify-between items-center gap-4">
        <div className="flex items-center space-x-2 gap-2">
          <Avatar
            firstName={storeData?.storeName}
            imageSrc={storeData?.settings?.logo}
            size={32}
          />
          <h1 className="text-lg font-semibold leading-tight tracking-tight text-primary">
            {storeData?.storeName}
          </h1>
        </div>
        <button className="" onClick={onClose}>
          <img src={x} alt="cancel" srcset="" />
        </button>
      </div>

      <div className="mt-10 px-6">
        <ul className="flex flex-col gap-8  text-lg bg-red-500">
          <a onClick={handleCartModal}>
            <li className="cursor-pointer flex items-center gap-4 bg-red-500">
               <img src={cart} className="text-red-500"/> Cart
            </li>
          </a>
          <Link >
            <li className="cursor-pointer flex items-center gap-4">
               <img src={promo} className="text-red-500"/> Promotions
            </li>
          </Link>
          <Link >
            <li className="cursor-pointer flex items-center gap-4">
               <img src={category} className="text-red-500"/> Categories
            </li>
          </Link>
          <Link >
            <li className="cursor-pointer flex items-center gap-4">
               <img src={track} className="text-red-500"/> Track Order
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
