import React, { useEffect } from 'react';
import InputField from '../input_field';
import noCoupon from '../../../../assets/images/no-coupon.png';
import { BsFillTicketFill } from 'react-icons/bs';
import Button from '../button.js';
import ModalHeader from '../modalHeader.js';
import PromotionCard from '../promotioncard.js';
import { useAPI } from '../../../../api/api_context.js';
import { useDispatch, useSelector } from 'react-redux';
import { setCoupons, selectCoupon } from '../../../../store/couponSlice.js';
import { toast } from 'sonner';

const Coupon = ({
	setCouponModalOpen,
	setAppliedCoupon,
	setDiscountAmount,
}) => {
	const dispatch = useDispatch();
	const { coupons, loading, selectedCoupon } = useSelector(
		(state) => state.coupon,
	);
	const apiService = useAPI();

	useEffect(() => {
		const fetchCoupons = async () => {
			try {
				const couponsData = await apiService.getCoupons();
				dispatch(
					setCoupons({
						coupons: couponsData.data,
						loading: false,
						error: null,
					}),
				);
			} catch (error) {
				dispatch(
					setCoupons({
						coupons: [],
						loading: false,
						error: 'Failed to load coupons.',
					}),
				);
				console.error('Error fetching coupons:', error);
			}
		};

		fetchCoupons();
	}, [apiService, dispatch]);

	const handleApplyCoupon = async () => {
		if (!selectedCoupon || !selectedCoupon.code) {
			alert('Please select or enter a valid coupon.');
			return;
		}

		try {
			const response = await apiService.applyCoupon(selectedCoupon.code);
			setAppliedCoupon(response.data.appliedCoupon);
			setDiscountAmount(response.data.discountAmount);
			toast.success(`Coupon applied successfully`);
			setCouponModalOpen(false);
		} catch (error) {
			console.error('Error applying coupon:', error);
			toast.error('Failed to apply coupon. Please try again.');
		}
	};

	const handleCouponSelect = (coupon) => {
		dispatch(selectCoupon(coupon));
	};

	return (
		<div
			onClick={() => setCouponModalOpen(false)}
			className='fixed inset-0 flex items-center justify-center bg-primary bg-opacity-90 z-50 modal-overlay'>
			<div
				className='bg-white rounded-lg max-w-md w-full mx-4 relative max-h-[90%] overflow-hidden'
				onClick={(e) => e.stopPropagation()}>
				<ModalHeader
					header='Promotions'
					onClick={() => setCouponModalOpen(false)}
				/>

				<div className='flex p-4 gap-2 items-center justify-center w-full'>
					<InputField
						placeholder='Enter promo code here'
						className='w-full'
						name='coupon'
						type='text'
						value={selectedCoupon?.code || ''}
						prefix={
							<BsFillTicketFill className='w-[16px] h-[16px]' />
						}
					/>
					<Button
						label='Apply'
						className='w-fit rounded-xl'
						onClick={(e) => {
							handleApplyCoupon();
						}}
					/>
				</div>
				<div className='space-y-4 px-4 pb-4'>
					<h3 className='text-secondary text-[14px]'>
						Available Promotions
					</h3>
					{loading ? (
						<div className='flex justify-center items-center py-12'>
							<div className='spinner w-8 h-8 border-4 border-t-transparent border-gray-400 rounded-full animate-spin'></div>
						</div>
					) : coupons.length === 0 ? (
						<div className='flex flex-col gap-8 items-center justify-center'>
							<img
								src={noCoupon}
								alt='No coupon available'
								className='w-[96px] h-[96px]'
							/>
							<p className='text-center leading-[21px] text-primary'>
								You don’t have any promotions at this time
							</p>
						</div>
					) : (
						<div className='flex flex-col gap-3 max-h-[350px] overflow-y-scroll overflow-hidden no-scrollbar'>
							{coupons
								.filter((promo) => promo.active)
								.map((promo) => (
									<div
										key={promo._id}
										style={{ scrollSnapAlign: 'start' }}>
										<PromotionCard
											code={promo.code}
											discountValue={promo.discountValue}
											expiry={promo.expiry}
											maxUsage={promo.maxUsage}
											active={promo.active}
											couponType={promo.couponType}
											onClick={(e) => {
												handleCouponSelect(promo);
											}}
										/>
									</div>
								))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Coupon;
