import React from 'react';
import DropDown from '../../../assets/images/dropdown.svg';
import { MdArrowDropUp } from 'react-icons/md';
import useToggle from '../../../custom-hooks/useToggle';

const FAQBox = ({ question, answer }) => {
	const { isOpen, toggleAnswer } = useToggle();

	return (
		<div className='flex flex-col gap-5 w-full lg:px-[20px] lg:w-full'>
			<div
				className=' flex justify-between w-full'
				data-aos='fade-down'>
				<h2 className='text-primary font-[400] text-[20px]  leading-[30px] -tracking-[3%] w-[80%] lg:w-[400px]'>
					{question}
				</h2>
				<button
					className=' w-[64px] h-[24px] '
					onClick={toggleAnswer}>
					{isOpen ? (
						<MdArrowDropUp className='w-[74px] h-[34px]' />
					) : (
						<img
							src={DropDown}
							alt='Drop down button'
							className='w-full h-full'
						/>
					)}
				</button>
			</div>
			{isOpen && (
				<div className=''>
					<p className='text-secondary font-[400]   leading-[24px] -tracking-[3%]'>
						{' '}
						{answer}
					</p>
				</div>
			)}
		</div>
	);
};

export default FAQBox;
