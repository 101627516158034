import React, { useState } from 'react';
import InputField from './input_field';
import Button from './button';
import ModalHeader from './modalHeader';
import { useAPI } from '../../../api/api_context';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'sonner';
import { addDeliveryInstruction } from '../../../store/deliveryInstructionSlice';

const DeliveryInstructions = ({ setInstructionsModalOpen }) => {
	const apiservice = useAPI();
	const dispatch = useDispatch();
	const { deliveryInstructions } = useSelector((state) => state.instruction);
	const [note, setNote] = useState(deliveryInstructions?.note || '');
	const [alternatePhone, setAlternatePhone] = useState(
		deliveryInstructions?.alternatePhone || '',
	);
	const [closestLandmark, setClosestLandmark] = useState(
		deliveryInstructions?.closestLandmark || '',
	);
	const handleSave = async () => {
		try {
			const response = await apiservice.addDeliveryInstructions(
				note,
				alternatePhone,
				closestLandmark,
			);
			console.log('Response',response);
			// dispatch(addDeliveryInstruction)
			dispatch(addDeliveryInstruction({
				note: response.note,
				alternatePhone: response.alternatePhone,
				closestLandmark: response.closestLandmark
			}));
			toast.success('Delivery Instructions added successfully');
			setInstructionsModalOpen(false); // Close modal
		} catch (error) {
			dispatch(addDeliveryInstruction(error.message));
			toast.error('Error adding delivery instructions');
			console.error('Error:', error);
		}
	}

	
	return (
		<div
			onClick={() => setInstructionsModalOpen(false)}
			className='fixed inset-0 bg-primary bg-opacity-50 z-50 flex items-center justify-center modal-overlay'>
			<div
				onClick={(e) => e.stopPropagation()}
				className='bg-white rounded-lg max-w-md w-full mx-4 relative'>
				<ModalHeader
					header=' Delivery instructions'
					onClick={() => setInstructionsModalOpen(false)}
				/>

				<div className='flex flex-col gap-4 w-full p-4'>
					<InputField
						label='Instruction for courier'
						value={note}
						handleValue={setNote}
						placeholder='e.g. Leave package with the security personnel at the first building'
						name=''
						type='textarea'
					/>

					<InputField
						label='Additional phone number'
						value={alternatePhone}
						handleValue={setAlternatePhone}
						placeholder='+234 7045533534'
						name=''
						type='number'
					/>
					<InputField
						label='Landmark near me'
						value={closestLandmark}
						handleValue={setClosestLandmark}
						placeholder='Methodist Church, Yaba'
						name=''
						type='text'
					/>
					<Button
						type='primary'
						label={'Done'}
						className='w-full rounded-full'
						onClick={handleSave}
					/>
				</div>
			</div>
		</div>
	);
};

export default DeliveryInstructions;

