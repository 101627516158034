import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/LandingPage/Home';
import About from './pages/LandingPage/About';
import Community from './pages/LandingPage/Community';
import Blog from './pages/LandingPage/Blog';
import BlogDetail from './pages/LandingPage/components/BlogDetail';
import Dashboard from './pages/LandingPage/components/Dashboard';
import Events from './pages/LandingPage/components/Events';
import PrivacyPolicy from './pages/LandingPage/PrivacyPolicy';
import { paths } from './routes';
import { ApiProvider } from './api/api_context';
import StoreContainer from './pages/Container';
import ProductDetails from './pages/StoreFront/product_details';
import { Toaster } from 'sonner';
import OrderTracking from './pages/Order Tracking/order_tracking.js';
import Cart from './pages/StoreFront/components/checkout/cart';
import { Provider } from 'react-redux';
import { store } from './store/index.js';
import Checkout from './pages/StoreFront/checkout.js';
import StoreFront from './pages/StoreFront/StoreFront.js';
import { WebSocketProvider } from './utils/websocketContext.js';

const App = () => {
	return (
		<>
			<Provider store={store}>
				<WebSocketProvider>
					<Toaster position='top-right' />
					<ApiProvider>
						<Router>
							<Routes>
								<Route
									exact
									path={paths.HOME}
									element={<Home />}
								/>
								<Route
									path={paths.ABOUT}
									element={<About />}
								/>
								<Route
									path={paths.COMMUNITY}
									element={<Community />}
								/>
								<Route
									path={paths.BLOG}
									element={<Blog />}
								/>
								<Route
									path={paths.BLOG_DETAIL}
									element={<BlogDetail />}
								/>
								<Route
									path={paths.DASHBOARD}
									element={<Dashboard />}
								/>
								<Route
									path={paths.PRIVACY_POLICY}
									element={<PrivacyPolicy />}
								/>
								<Route
									path={paths.EVENTS}
									element={<Events />}
								/>

								<Route
									path='/:storeIdentifier/*'
									element={<StoreContainer />}>
									<Route
										index
										element={<StoreFront />}
									/>
									<Route
										path=':categorySlug/:productId'
										element={<ProductDetails />}
									/>
									<Route
										path='checkout'
										element={<Checkout />}
									/>
									<Route
										path='order-tracking'
										element={<OrderTracking />}
									/>
									<Route
										path='cart'
										element={<Cart />}
									/>
								</Route>
							</Routes>
						</Router>
					</ApiProvider>
				</WebSocketProvider>
			</Provider>
		</>
	);
};

export default App;
