import axios from 'axios';

const isTestEnv = process.env.REACT_APP_ENV === 'test';
const baseURL = isTestEnv
	? 'http://localhost:1222/api'
	: 'https://' + process.env.REACT_APP_CENTRAL_SERVER + '/api';

export const api = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json',
	},
});
