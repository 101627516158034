import React from 'react';
import { numberWithCommas } from '../utils/add_commas';

const CartSummary = ({ item }) => {
	return (
		<>
			<div className='flex items-center gap-4'>
				<img
					src={item.images[0]}
					alt={`Product ${item.name}`}
					className='w-[64px] h-[64px] object-cover rounded'
				/>

				<div className='flex flex-col gap-2 '>
					<h2 className='text-primary font-medium text-[18px] leading-[21px] -tracking-[3%]'>
						{item.name}
					</h2>
					<p className='text-secondary  text-[13px] leading-[16px] -tracking-[3%]'>
						NGN {numberWithCommas(item.price * item.quantity)}
					</p>
				</div>
			</div>
			<div className='flex items-center gap-4'>
				<p className='text-secondary  text-[13px] leading-[18px] -tracking-[3%]'>
					Qty
				</p>
				<div className='w-auto h-[26px] rounded-[8px] py-1 px-2 bg-[#F5F5F5] border border-[#CECECE] flex justify-center items-center text-primary  text-[13px] leadin-[18px] -tracking-[3%]'>
					{item.quantity}
				</div>
			</div>
		</>
	);
};

export default CartSummary;
