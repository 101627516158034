import { createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
	storeName: '',
	storeLink: '',
	storeIdentifier: '',
	businessType: '',
	storePhone: '',
	storeHeader: '',
	contactEmail: '',
	location: {
		address: '',
		coordinates: [],
		noPhysicalLocation: false,
	},
	settings: {
		logo: null,
		storeHeaderColor: '#FFFFFF',
		buttonColor: '#0B6E4F',
		outOfStockBehavior: 'Show as sold out',
	},
	categories: [],
	coupons: [],
	subscription: {
		tier: 'Free',
		subscriptionStartDate: '',
		nextBillingDate: '',
		subscriptionCode: '',
		status: 'inactive',
		authorizationCode: '',
		subscriptionActive: false,
		plan: 'Free',
	},
	payouts: [],
	withdrawalRequests: [],
	// searchQuery:"",
	// searchResults: []
};

// Redux slice
const storeSlice = createSlice({
	name: 'store',
	initialState,
	reducers: {
		setStoreDetails: (state, action) => {
			const {
				storeName,
				storeLink,
				storeIdentifier,
				businessType,
				storePhone,
				storeHeader,
				contactEmail,
				location: { address, location, noPhysicalLocation },
				settings,
				categories,
				subscription,
				payouts,
				withdrawalRequests,
			} = action.payload;

			state.storeName = storeName;
			state.storeLink = storeLink;
			state.storeIdentifier = storeIdentifier;
			state.businessType = businessType;
			state.storePhone = storePhone;
			state.storeHeader = storeHeader;
			state.contactEmail = contactEmail;
			state.location = {
				address,
				coordinates: location.coordinates,
				noPhysicalLocation,
			};
			state.settings = settings;
			state.categories = categories;
			state.subscription = subscription;
			state.payouts = payouts;
			state.withdrawalRequests = withdrawalRequests;
		},
		updateStoreSettings: (state, action) => {
			state.settings = { ...state.settings, ...action.payload };
		},
		addCategory: (state, action) => {
			state.categories.push(action.payload);
		},
		updateCategory: (state, action) => {
			const { categoryId, updatedCategory } = action.payload;
			const categoryIndex = state.categories.findIndex(
				(cat) => cat._id === categoryId,
			);
			if (categoryIndex !== -1) {
				state.categories[categoryIndex] = updatedCategory;
			}
		},
		removeCategory: (state, action) => {
			state.categories = state.categories.filter(
				(cat) => cat._id !== action.payload,
			);
		},
		addCoupon: (state, action) => {
			state.coupons.push(action.payload);
		},
		updateCoupon: (state, action) => {
			const { couponId, updatedCoupon } = action.payload;
			const couponIndex = state.coupons.findIndex(
				(coupon) => coupon._id === couponId,
			);
			if (couponIndex !== -1) {
				state.coupons[couponIndex] = updatedCoupon;
			}
		},
		removeCoupon: (state, action) => {
			state.coupons = state.coupons.filter(
				(coupon) => coupon._id !== action.payload,
			);
		},
	},
});

// Actions
export const {
	setStoreDetails,
	updateStoreSettings,
	addCategory,
	updateCategory,
	removeCategory,
	addCoupon,
	updateCoupon,
	removeCoupon,
	setSearchQuery,
} = storeSlice.actions;

// Reducer
export default storeSlice.reducer;
