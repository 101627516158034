import React from 'react';
import ArrowRight from '../../../assets/images/arrow-right.svg';
import Instagram from '../../../assets/images/instagram.svg';
import Twitter from '../../../assets/images/twitter.svg';
import Facebook from '../../../assets/images/facebook.svg';
import LinkedIn from '../../../assets/images/linkedin.svg';

const Footer = () => {
	return (
		<div className='flex flex-col lg:flex-row justify-center gap-8 px-[20px] py-[50px] lg:px-[80px] lg:py-[70px]'>
			<div className='flex flex-col items-start gap-6'>
				<h1 className='text-primary text-[32px] leading-[40px] lg:text-[46px] lg:leading-[57px] font-[400] -tracking-[3%] '>
					Ready to ditch the delivery drama?
				</h1>
				<p className='text-secondary  leading-[24px] lg:text-[18px] lg:leading-[27px] font-[400] -tracking-[3%]'>
					Join the RideFraser revolution and experience stress-free
					shipping for your online store.
				</p>
				<div className='flex flex-col items-start  gap-5 mt-[40px] mb-[20px] w-full'>
					<a
						href='https://www.merchant.ridefraser.com/signin'
						target='_blank'
						rel='noreferrer'
						className='flex justify-center items-center gap-3 py-[16px] px-[50px] w-full md:w-[230px] h-[56px] bg-basegreen rounded-[30px] cursor-pointer'>
						<span className='text-white  leading-[24px] font-[400] -tracking-[3%]'>
							Get Started
						</span>
						<img
							src={ArrowRight}
							alt='Get started button'
							className='w-[24px] h-[24px]'
						/>
					</a>
					<a
						href='https://courier.ridefraser.com/signin'
						target='_blank'
						rel='noreferrer'
						className='cursor-pointer text-basegreen  leading-[18px] font-[400] -tracking-[3%]'>
						or signup as Courier
					</a>
				</div>
			</div>
			<div className='flex flex-col'>
				<div className='flex flex-col lg:flex-row lg:justify-between gap-14 lg:gap-5 py-[20px]'>
					<div className='flex flex-col gap-7 w-full lg:w-[300px]'>
						<h3 className='text-primary  leading-[18px]  font-[400] -tracking-[3%]'>
							Company
						</h3>
						<a
							href='/about'
							className='text-secondary  leading-[18px]  font-[400] -tracking-[3%]'>
							About Us
						</a>
						<a
							href='/blog'
							className='text-secondary  leading-[18px]  font-[400] -tracking-[3%]'>
							Blog
						</a>
						<a
							href='/community'
							className='text-secondary  leading-[18px]  font-[400] -tracking-[3%]'>
							Join our community
						</a>
					</div>
					<div className='flex flex-col gap-7 w-full lg:w-[300px]'>
						<h3 className='text-primary  leading-[18px]  font-[400] -tracking-[3%]'>
							Developer
						</h3>
						<a
							href='https://fraser.stoplight.io/docs/central/cbb186dc155a8-merchant-management'
							target='_blank'
							rel='noreferrer'
							className='text-secondary  leading-[18px]  font-[400] -tracking-[3%]'>
							API Documentation
						</a>
					</div>
				</div>
				<div className='flex flex-col lg:flex-row lg:justify-between gap-14 lg:gap-5 py-[30px] '>
					<div className='flex flex-col gap-7 w-full lg:w-[300px]'>
						<h3 className='text-primary  leading-[18px]  font-[400] -tracking-[3%]'>
							Contact Us
						</h3>
						<p className='text-secondary  leading-[18px]  font-[400] -tracking-[3%]'>
							+234 7045533534
						</p>
						<p className='text-secondary  leading-[18px]  font-[400] -tracking-[3%]'>
							contact@ridefraser.com
						</p>
						<div className='flex gap-3'>
							<a
								href='https://www.instagram.com/ridefraser?igsh=NTc4MTIwNjQ2YQ=='
								target='_blank'
								rel='noreferrer'>
								{' '}
								<img
									src={Instagram}
									alt='Link to Ridefraser Instagram page'
								/>
							</a>
							<a
								href='https://www.linkedin.com/company/ridefraser/'
								target='_blank'
								rel='noreferrer'>
								<img
									src={LinkedIn}
									alt='Link to Ridefraser LinkedIn page'
								/>
							</a>
							<a
								href='https://www.facebook.com/profile.php?id=100091518573108'
								target='_blank'
								rel='noreferrer'>
								<img
									src={Facebook}
									alt='Link to Ridefraser Facebook page'
								/>
							</a>
							<a
								href='https://x.com/ridefraser?s=09'
								target='_blank'
								rel='noreferrer'>
								<img
									src={Twitter}
									alt='Link to Ridefraser X or Twitter page'
								/>
							</a>
						</div>
					</div>
					<div className='flex flex-col gap-7 w-full lg:w-[300px]'>
						<h3 className='text-primary  leading-[18px]  font-[400] -tracking-[3%]'>
							Legal
						</h3>
						<a
							href='/'
							className='text-secondary  leading-[18px]  font-[400] -tracking-[3%]'>
							Terms and Conditions
						</a>
						<a
							href='/'
							className='text-secondary  leading-[18px]  font-[400] -tracking-[3%]'>
							Privacy Policy
						</a>
					</div>
				</div>
				<div className='flex flex-col lg:flex-row lg:justify-between gap-14 lg:gap-7 py-[20px]'>
					<div className='flex flex-col gap-7 w-full lg:w-[300px]'>
						<h3 className='text-primary  leading-[18px]  font-[400] -tracking-[3%]'>
							Lagos
						</h3>
						<p className='text-secondary  leading-[24px]  font-[400] -tracking-[3%]'>
							294 Herbert Macaulay Way, Sabo Yaba, Lagos Nigeria
						</p>
					</div>
					<div className='flex flex-col gap-7 w-full lg:w-[300px]'>
						<h3 className='text-primary  leading-[18px]  font-[400] -tracking-[3%]'>
							Ibadan
						</h3>
						<p className='text-secondary  leading-[24px]  font-[400] -tracking-[3%]'>
							Dreampod place, Heritage Mall, Dugbe, Ibadan, Oyo,
							Nigeria
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
