import { createSlice } from '@reduxjs/toolkit';

const customerDetailsSlice = createSlice({
	name: 'customer',
	initialState: {
		userDetails: { name: '', email: '', phone_number: '' },
	},
	reducers: {
		addCustomerDetails: (state, action) => {
			state.userDetails = action.payload;
			state.status = 'succeeded';
		},
	},
});

export const { addCustomerDetails } = customerDetailsSlice.actions;
export default customerDetailsSlice.reducer;
