import React from "react";
import { FaTimes } from "react-icons/fa";
import Avatar from "./avatar";
import { useSelector } from "react-redux";

const ModalHeader = ({ header, onClick, cartSize, className, payment }) => {
	const storeData = useSelector((state) => state.store);
  return (
    <div
      className={`flex justify-between items-center w-full border-b border-outline p-4 relative bg-white z-30 ${className} `}
    >
      {payment && (
        <Avatar
          firstName={storeData?.storeName}
          imageSrc={storeData?.settings?.logo}
          size={28}
        />
      )}
      <h1 className="text-primary font-[600] text-[20px] leading-[25px]">
        {header} {cartSize > 1 && <span>({cartSize} items)</span>}
      </h1>

      <button
        onClick={onClick}
        className={`rounded-full w-[32px] h-[32px] p-2 text-[13px] flex items-center justify-center text-gray-500 bg-formBG`}
      >
        <FaTimes />
      </button>
    </div>
  );
};

export default ModalHeader;
