import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchSearchResults = createAsyncThunk(
    'other/fetchSearchResults',
    async (query, { rejectWithValue }) => {
        try {
            const response = await axios.get(`https://central-test-server.onrender.com/api/store/estherstore/search/products?query=${query}`);
            return response.data;  // Assuming response data contains the search results
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);
export const fetchSearchSuggestions = createAsyncThunk(
    'other/fetchSearchSuggestions',
    async (query, { rejectWithValue }) => {
        try {
            const response = await axios.get(`https://central-test-server.onrender.com/api/store/estherstore/searchsuggestion?query=${query}`);
            return response.data;  // Assuming response data contains the search results
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);





const initialState = {
    searchQuery: "",
    searchResults: [],
    searchSuggestions: [],
    loading: false,
    error: null,
}

const otherSlice = createSlice({
    name: 'other',
    initialState,
    reducers: {
        setSearchQuery: (state, action)=>{
			state.searchQuery = action.payload
		},
        setSearchResults: (state, action) => {
            state.searchResults = action.payload;  // Update search results
        },
        setSearchSuggestions: (state, action) => {
            state.searchSuggestions = action.payload?.data
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearchResults.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSearchResults.fulfilled, (state, action) => {
                state.loading = false;
                state.searchResults = action.payload;
            })
            .addCase(fetchSearchResults.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchSearchSuggestions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSearchSuggestions.fulfilled, (state, action) => {
                state.loading = false;
                state.searchSuggestions = action.payload?.data;
            })
            .addCase(fetchSearchSuggestions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
})

export const {setSearchQuery, setSearchResults, setSearchSuggestions} = otherSlice.actions

export default otherSlice.reducer