import React from 'react';
import Delivery from '../../../assets/images/store/package.png';
import Discount from '../../../assets/images/store/discount.png';
import Clearance from '../../../assets/images/store/clearance.png';
import Holiday from '../../../assets/images/store/santa-hat.png';
import Coin from '../../../assets/images/store/coin.png';
import Calendar from '../../../assets/images/store/calendar.png';

const promotionConfig = {
	delivery: {
		icon: '🚚',
		color: 'bg-[#8F3985]',
		subtitle: 'Free delivery on your order',
		description:
			'Get your items delivered for free when you use this coupon.',
		imageUrl: Delivery,
	},
	'first-time': {
		icon: '🆕',
		color: 'bg-[#0B6E4F]',
		subtitle: 'Welcome Offer for First-Time Shoppers',
		description:
			'Exclusive discount for first-time buyers. Start saving today!',
		imageUrl: Calendar,
	},
	promotion: {
		icon: '%',
		color: 'bg-[#040404]',
		subtitle: 'Special Store-Wide Discount',
		description: 'Apply this coupon to get a percentage off on all items.',
		imageUrl: Discount,
	},
	holiday: {
		icon: '🎉',
		color: 'bg-[#A31621]',
		subtitle: 'Holiday Sale - Limited Time Only!',
		description: 'Celebrate the season with discounts on selected items!',
		imageUrl: Holiday,
	},
	clearance: {
		icon: '🏷️',
		color: 'bg-[#0B6E4F]',
		subtitle: 'Clearance Deals - While Stocks Last!',
		description: 'Grab final sale items at unbeatable prices.',
		imageUrl: Clearance,
	},
	loyalty: {
		icon: '🏅',
		color: 'bg-[#380036]',
		subtitle: 'Loyalty Reward',
		description:
			'Thank you for being a loyal customer. Enjoy this exclusive discount.',
		imageUrl: Coin,
	},
	shipping: {
		icon: '📦',
		color: 'bg-[#8F3985]',
		subtitle: 'Discounted Shipping',
		description: 'Enjoy reduced shipping fees with this coupon.',
		imageUrl: Delivery,
	},
};

export default function PromotionCard({
	onClick,
	code,
	discountValue,
	expiry,
	maxUsage,
	active,
	couponType,
}) {
	const { color, subtitle, imageUrl } =
		promotionConfig[couponType] || promotionConfig.promotion;

	return (
		<div
			onClick={onClick}
			className={`${color} cursor-pointer h-[8rem] w-full overflow-hidden transition-all rounded-xl border`}>
			<div className='text-white p-8'>
				<div className='flex items-center justify-between space-x-3'>
					<div className='w-3/4 space-y-[2px]'>
						<h3 className=' font-semibold'>{`${discountValue}% Off`}</h3>
						<p className='text-[13px] font-light leading-tight'>
							{subtitle}
						</p>
					</div>
					<img
						src={imageUrl}
						alt={`${couponType} icon`}
						className='h-16 w-16 object-contain'
					/>
				</div>
			</div>
		</div>
	);
}
