import React, { createContext, useContext, useMemo } from 'react';
import ApiService from './api_calls';

const MerchantContext = createContext(null);

export const useAPI = () => {
	const context = useContext(MerchantContext);
	if (!context) {
		throw new Error('useAPI must be used within a ApiProvider');
	}
	return context;
};

export const ApiProvider = ({ children }) => {
	const apiService = useMemo(() => new ApiService(), []);
	

	return (
		<MerchantContext.Provider value={apiService}>
			{children}
		</MerchantContext.Provider>
	);
};

