import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FaLocationArrow } from 'react-icons/fa';
import { debounce } from 'lodash';
import { toast } from 'sonner';
import { useAPI } from '../../../api/api_context';
import { useDispatch, useSelector } from 'react-redux';
import {
	setDeliveryCost,
	setETA,
	setUserLocation,
} from '../../../store/locationSlice';
import { useLocationService } from '../utils/useLocationService';
import ModalHeader from './modalHeader';

const AddressModal = ({
	isOpen,
	onClose,
	initialAddress = '',
	onSelectAddress,
}) => {
	const apiService = useAPI();
	const dispatch = useDispatch();
	const [baddress, setBaddress] = useState(initialAddress);
	const [addressSuggestions, setAddressSuggestions] = useState([]);
	const [isGettingLocation, setIsGettingLocation] = useState(false);
	const addressInputRef = useRef(null);
	const modalRef = useRef(null);

	const { deliveryCost, eta } = useSelector((state) => state.location);

	useEffect(() => {
		if (isOpen && addressInputRef.current) {
			addressInputRef.current.focus();
		}
	}, [isOpen]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				onClose();
			}
		};
		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen, onClose]);

	const handleAddressSearch = (value) => {
		setBaddress(value);
		debouncedAddressSearch(value);
	};

	const selectAddress = async (address) => {
		try {
			const response = await apiService.getAddressCoordinates(address);
			const { latitude, longitude } = response.data;

			const location = await apiService.getCurrentUserLocation(
				latitude,
				longitude,
			);

			if (location?.data) {
				const {
					address: selectedAddress,
					deliveryCost,
					eta,
				} = location.data;

				dispatch(setUserLocation(selectedAddress));
				dispatch(setDeliveryCost(deliveryCost));
				dispatch(setETA(eta));

				onSelectAddress(selectedAddress);
				setAddressSuggestions([]);
				onClose();
			} else {
				throw new Error('Invalid location data received.');
			}
		} catch (error) {
			toast.error('Error selecting address. Please try again.');
		}
	};

	const debouncedAddressSearch = useCallback(
		debounce(async (value) => {
			if (value.length > 2) {
				try {
					const addresses = await apiService.searchAddresses(value);
					setAddressSuggestions(addresses);
				} catch (error) {
					toast.error('Error searching addresses. Please try again.');
				}
			} else {
				setAddressSuggestions([]);
			}
		}, 300),
		[],
	);

	const { fetchLocation } = useLocationService(
		apiService.getCurrentUserLocation,
	);

	const handleFetchLocation = () => {
		setIsGettingLocation(true);
		fetchLocation();
		setIsGettingLocation(false);
	};

	if (!isOpen) return null;

	return (
		<div className='fixed inset-0 bg-primary bg-opacity-90 z-50 flex md:items-center md:justify-center modal-overlay justify-end items-end' >
			<div
				ref={modalRef}
				className='bg-white rounded-lg max-w-md w-full mx-4 relative'>
				<ModalHeader
					header='Your Address'
					onClick={onClose}
				/>

				<div className='p-4'>
					<input
						ref={addressInputRef}
						type='text'
						value={baddress}
						onChange={(e) => handleAddressSearch(e.target.value)}
						placeholder='Search for an address'
						className='w-full h-[48px] rounded-lg bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] placeholder-gray-400 leading-[20px] text-primary border border-[#e3e3e3] focus:border-green-600'
						autoFocus
					/>
					<div
						className='bg-gray-100 mt-2 p-3 rounded-lg flex space-x-3 items-center cursor-pointer'
						onClick={handleFetchLocation}>
						<FaLocationArrow />
						<div>
							<p className='font-medium'>
								Use my Current Location
							</p>
							<p className='text-[13px] text-gray-500'>
								{isGettingLocation
									? 'Getting location...'
									: 'Click to get current location'}
							</p>
						</div>
					</div>
					<ul className='space-y-2 max-h-60 overflow-auto mt-4'>
						{addressSuggestions.map((address, index) => (
							<li
								key={index}
								className='flex items-center p-2 hover:bg-gray-100 cursor-pointer'
								onClick={() => selectAddress(address)}>
								<FaLocationArrow className='mr-2' />
								<div>
									<p className='font-medium'>
										{address.formatted_address ||
											address.place_name}
									</p>
									<p className='text-[13px] text-gray-500'>
										{address.description}
									</p>
								</div>
							</li>
						))}
					</ul>
					<div className='flex space-x-8 mt-4'>
						<div className='flex flex-col space-y-1'>
							<p className='text-[13px] text-secondary'>
								Delivery Cost
							</p>
							<p>{deliveryCost || 'N/A'}</p>
						</div>
						<div className='flex flex-col space-y-1'>
							<p className='text-[13px] text-secondary'>
								Delivery Time
							</p>
							<p>{eta || 'N/A'}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddressModal;
