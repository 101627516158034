import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../LandingPage/components/Hero';
import Information from '../LandingPage/components/Information';
import LandingPageLayout from '../../Layouts/LandingLayout';

const Community = () => {
	return (
		<LandingPageLayout>
			<Helmet>
				<title>
					Join the Ridefraser Community: Empowering Logistics
					Innovation
				</title>
				<meta
					name='description'
					content='Become part of the Ridefraser community and join a network of innovators, merchants, and couriers transforming the e-commerce logistics landscape in Nigeria.'
				/>
				<meta
					name='keywords'
					content='Ridefraser community, logistics network, e-commerce logistics, courier services, Nigerian merchants, logistics innovation'
				/>
				<meta
					property='og:title'
					content='Join the Ridefraser Community: Empowering Logistics Innovation'
				/>
				<meta
					property='og:description'
					content="Ridefraser brings together merchants, couriers, and customers to create a seamless logistics experience in Nigeria's fast-growing e-commerce ecosystem."
				/>
				<meta
					property='og:type'
					content='website'
				/>
				<meta
					property='og:url'
					content='https://ridefraser.com/community'
				/>
				<meta
					property='og:image'
					content='https://ridefraser.com/og-community-image.jpg'
				/>
				<link
					rel='canonical'
					href='https://ridefraser.com/community'
				/>
			</Helmet>

			{/* Hero Section */}
			<Hero />

			{/* Information Section */}
			<Information />
		</LandingPageLayout>
	);
};

export default Community;
