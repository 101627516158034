import { createSlice } from '@reduxjs/toolkit';

const deliveryInstructionsSlice = createSlice({
	name: 'instruction',
	initialState: {
		deliveryInstructions: {
			note: '',
			alternatePhone: '',
			closestLandmark: '',
		},
	},
	reducers: {
		addDeliveryInstruction: (state, action) => {
			state.deliveryInstructions = action.payload;
			state.status = 'succeeded';
		},
	},
});
export const { addDeliveryInstruction } = deliveryInstructionsSlice.actions;
export default deliveryInstructionsSlice.reducer;
