import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCoordinates, setDeliveryCost, setETA, setUserLocation } from '../../../store/locationSlice';
import { getLocationUtility } from './getLocationUtility';


export const useLocationService = (getCurrentUserLocation) => {
	const dispatch = useDispatch();
	const [error, setError] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const fetchLocation = async () => {
		try {
			const location = await getLocationUtility(getCurrentUserLocation);
			dispatch(setUserLocation(location.address));
			dispatch(setDeliveryCost(location.deliveryCost));
			dispatch(setETA(location.eta));
			dispatch(setCoordinates({ latitude: location.latitude, longitude: location.longitude }));
		} catch (error) {
			setError(error.message);
			setIsModalOpen(true);
		}
	};

	const retryFetchLocation = async () => {
		setIsModalOpen(false);
		await fetchLocation();
	};

	return {
		fetchLocation,
		error,
		isModalOpen,
		closeModal: () => setIsModalOpen(false),
		retryFetchLocation,
	};
};
