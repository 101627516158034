import React, { useEffect, useState } from 'react';
import ModalHeader from '../modalHeader';
import Button from '../button';
import { ImSpinner8 } from 'react-icons/im';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'sonner';
import { numberWithCommas } from '../../utils/add_commas';
import { useWebSocket } from '../../../../utils/websocketContext';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { cartOpen, clearCart } from '../../../../store/cartSlice';

const Payment = ({
	handleCloseModal,
	accountNumber,
	accountName,
	expiresAt,
	amountToPay,
	bank,
}) => {
	const storeData = useSelector((state) => state.store);
	const ws = useWebSocket();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [state, setState] = useState({
		isTransactionInProgress: false,
		isTransferConfirmed: false,
		isDetailsVisible: false,
		timeRemaining: '',
		isExpired: false,
		pin: [],
		orderId: '',
		customerEmail: '',
		orderTrackingLink: '',
	});

	useEffect(() => {
		if (ws) {
			ws.onmessage = (message) => {
				const data = JSON.parse(message.data);
				console.log('Received WebSocket message:', data);
				if (
					data.event === 'chargeSuccess' &&
					data.payload?.paymentDetails?.status === 'success'
				) {
					setState((prev) => ({
						...prev,
						isTransactionInProgress: false,
						isTransferConfirmed: true,
						pin: data.payload?.authentication?.pin
							? String(data.payload.authentication.pin).split('')
							: [],
						orderId: data.payload?.customId,
						customerEmail: data?.payload?.customer?.email,
						modalTimer: 10,
					}));

					// Start countdown for modal close
					let timer = 10;
					const countdown = setInterval(() => {
						timer -= 1;
						setState((prev) => ({ ...prev, modalTimer: timer }));
						if (timer === 0) {
							clearInterval(countdown);
							const currentPath = window.location.pathname;
							const targetPath = `/${storeData.storeIdentifier}`;

							if (currentPath !== targetPath) {
								navigate(targetPath);
							}
							handleCloseModal();
							dispatch(cartOpen(false));
							dispatch(clearCart());
						}
					}, 1000);
				} else {
					setState((prev) => ({
						...prev,
						isTransactionInProgress: true,
					}));
				}
			};
		}
		return () => {
			if (ws) ws.onmessage = null;
		};
	}, [ws, handleCloseModal, dispatch, storeData.storeIdentifier, navigate]);

	useEffect(() => {
		const calculateTimeRemaining = () => {
			const expirationTime = new Date(expiresAt).getTime();
			const currentTime = new Date().getTime();
			const difference = expirationTime - currentTime;

			if (difference <= 0) {
				setState((prev) => ({
					...prev,
					isExpired: true,
					timeRemaining: '00:00',
				}));
				return;
			}

			const minutes = Math.floor((difference / 1000 / 60) % 60);
			const seconds = Math.floor((difference / 1000) % 60);
			setState((prev) => ({
				...prev,
				timeRemaining: `${minutes.toString().padStart(2, '0')}:${seconds
					.toString()
					.padStart(2, '0')}`,
			}));
		};

		calculateTimeRemaining();
		const timer = setInterval(calculateTimeRemaining, 1000);
		return () => clearInterval(timer);
	}, [expiresAt]);

	const handleTransaction = () => {
		setState((prev) => ({
			...prev,
			isTransactionInProgress: true,
		}));
		setTimeout(() => {
			setState((prev) => ({
				...prev,
				isTransactionInProgress: false,
				isTransferConfirmed: true,
			}));
		}, 10000);
	};

	const handleCopyAccountNumber = () => {
		navigator.clipboard.writeText(accountNumber);
		toast('Account Number copied');
	};

	const renderContent = () => {
		switch (true) {
			case state.isExpired:
				return (
					<>
						<ModalHeader
							payment={true}
							header='Payment Expired'
							onClick={handleCloseModal}
						/>
						<div className='px-6 py-8 flex flex-col items-center'>
							<IoInformationCircleOutline className='text-6xl text-[#CA1616] mb-4' />
							<h2 className='text-[18px] text-primary mb-2'>
								Payment Window Expired
							</h2>
							<p className='text-[15px] text-secondary text-center mb-6'>
								The payment window for this transaction has
								closed. Please initiate a new transaction.
							</p>
							<Button
								label='Close'
								type='primary'
								className='w-full'
								onClick={handleCloseModal}
							/>
						</div>
					</>
				);

			case state.isTransactionInProgress:
				return (
					<>
						<ModalHeader
							payment={true}
							header='Payment method'
							onClick={handleCloseModal}
						/>
						<div className='px-6 flex flex-col'>
							<div className='flex flex-col items-center w-full py-6'>
								<h3 className='text-primary text-center'>
									Transferring NGN {numberWithCommas(amountToPay / 100)} to{' '}
									{storeData.storeName}
								</h3>
								<div className='flex flex-col items-center gap-3 my-8'>
									<ImSpinner8 className='animate-spin text-2xl text-primary' />
									<p className='text-center text-secondary'>
										Please wait while we confirm your
										payment.
									</p>
								</div>
							</div>
						</div>
					</>
				);

			case state.isTransferConfirmed:
				return (
					<>
						<ModalHeader
							payment={true}
							header='Payment method'
							onClick={handleCloseModal}
						/>
						<div className='p-6 flex flex-col'>
							<div className='flex flex-col justify-center items-center'>
								<FaRegCircleCheck className='w-[40px] h-[40px] text-basegreen' />
								<h1 className='mt-4 mb-[12px] font-medium text-[21px] text-primary'>
									Order Completed
								</h1>
								<div className='flex gap-2 items-center mb-5'>
									<h2 className='  text-primary'>
										Order ID: {state?.orderId}
									</h2>
									<i
										className='fi fi-sr-copy'
										style={{
											color: '#0B6E4F',
											fontSize: '16px',
										}}></i>
								</div>
								<p className='text-center mb-[40px]  text-[14px] text-secondary'>
									Thanks for shopping with us. We’ve sent an
									email with your order summary to{' '}
									<span className=''>
										{state?.customerEmail}
									</span>
								</p>
								<div className='flex items-center justify-center gap-4 w-[281px] h-[45px] rounded-[8px]  bg-[#FFF6E8]'>
									<button className=''>
										<IoInformationCircleOutline className='w-[22px] h-[22px] text-[#A06841]' />
									</button>
									<p className='   leading-[21px] text-[#A06841]'>
										Present this to your courier
									</p>
								</div>
							</div>
							<div className='flex justify-center items-center gap-6 mt-4'>
								{state.pin.map((digit, index) => (
									<input
										key={index}
										type='text'
										value={digit}
										readOnly
										className='w-[64px] h-[64px] text-center border-none rounded-[8px] p-[10px] bg-[#F5F5F5] focus:outline-none  leading-[21px]  text-primary'
									/>
								))}
							</div>
							<p className='text-center mb-[40px]  text-[14px] text-secondary'>
								Thanks for shopping with us. The modal will
								close automatically in{' '}
								<span className='text-basegreen'>
									{state.modalTimer} seconds
								</span>
								.
							</p>
						</div>
					</>
				);

			default:
				return (
					<>
						<ModalHeader
							payment={true}
							header='Payment method'
							onClick={handleCloseModal}
						/>
						<div className='px-6 flex flex-col '>
							<div className='flex justify-between items-center border-b border-outline gap-4 py-6'>
								<div className='flex items-center gap-3'>
									<i className='fi fi-sr-bank flex items-center justify-center'></i>
									<h2 className='text-[15px] text-primary'>
										Bank transfer
									</h2>
								</div>
								<input
									defaultChecked
									type='radio'
									className='form-radio text-primary focus:ring-0'
								/>
							</div>

							<div className='flex flex-col justify-center items-center'>
								<div className='flex flex-col items-start w-full border-b border-outline gap-1 py-4'>
									<h3 className='text-[13px]  text-secondary'>
										Amount to pay
									</h3>
									<h2 className='text-[21px] font-semibold text-basegreen'>
										NGN{' '}
										{numberWithCommas(amountToPay / 100)}
									</h2>
								</div>
								<p className='text-primary text-[13px] py-4'>
									Transfer to account details below
								</p>
								<div className='flex w-full flex-col gap-4 items-center p-6 bg-[#F5F5F5] border border-[#CECECE] rounded-[12px]'>
									<div className='space-y-1 items-center'>
										<h2 className='text-center text-[15px] text-secondary'>
											{bank}
										</h2>
										<div className='flex gap-2 items-center'>
											<h2 className='text-center text-[20px] text-primary'>
												{accountNumber}
											</h2>
											<button
												className='underline text-basegreen text-center text-[13px] '
												onClick={
													handleCopyAccountNumber
												}>
												Copy
											</button>
										</div>
									</div>
									<div className='space-y-1 items-center'>
										<p className='text-[13px] text-center text-secondary'>
											Account name
										</p>
										<h2 className=' font-semibold text-primary'>
											{accountName}
										</h2>
									</div>
								</div>
							</div>

							<div className='space-y-6 items-center py-8'>
								<Button
									label='I have transferred the money'
									type='primary'
									className='w-full'
									onClick={handleTransaction}
									disabled={state.isTransactionInProgress}
								/>

								<p className=' text-[15px] text-center text-secondary'>
									This account is valid only for this
									transaction and expires in{' '}
									<span className='text-basegreen'>
										{state.timeRemaining}
									</span>
								</p>
							</div>
						</div>
					</>
				);
		}
	};

	return (
		<div
			className='fixed inset-0 bg-primary bg-opacity-90 z-50 flex items-center justify-center modal-overlay'
			onClick={handleCloseModal}>
			<div
				onClick={(e) => e.stopPropagation()}
				className='bg-white rounded-lg max-w-md w-full mx-4 relative overflow-hidden'>
				{renderContent()}
			</div>
		</div>
	);
};

export default Payment;
