import React from 'react';

const hero = () => {
	return (
		<div className='bg-hero-image bg-neutral '>
			<div className=' h-[90vh] flex md:pl-28 '>
				<div
					className='flex flex-col items-start justify-center md:w-[50%] gap-4  px-4 '
					data-aos='fade-right'>
					<h1 className='md:text-[46px] text-[28px] pt-20 font-normal text-primary tracking-tight md:leading-[57px] sm:leading-[35px]'>
						Get insights to scale your small business
					</h1>
					<a
						href='https://chat.whatsapp.com/BOLQJm9lGuU9NudUffRqkw'
						target='_blank'
						rel='noreferrer'
						className='text-white  leading-[24px] font-[400]  h-[56px] bg-basegreen rounded-[30px] cursor-pointer py-[16px] px-[50px]'>
						Join the Community
					</a>
					{/* <button>Join Our Community</button> */}
				</div>
				{/* <div className="pt-20 md:w-[30%]">djsjjs</div> */}
			</div>
		</div>
	);
};

export default hero;
