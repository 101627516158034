import React from 'react'
import Shipping from '../../../assets/images/shipping.svg'
import Tracking from '../../../assets/images/tracking.svg'
import Payment from '../../../assets/images/payment.svg'
import FeaturesBox from './FeaturesBox'

const Features = () => {

   const featuresContent = [
        {
            id: 1,
            icon: Shipping,
            title: 'Automated Shipping and Returns',
            description: 'Wave goodbye to manual courier searches. We automatically match your orders with the best delivery partner.',
        },
        {

            id: 2,
            icon: Tracking,
            title: 'Real-Time Order Tracking and Status Updates',
            description: 'Know exactly where your packages are at all times. Our live tracking keeps you and your customers in the loop, building trust and reducing anxiety.',
        },
        {
            
            id: 3,
            icon: Payment,
            title: 'Secure Payments and Pay on Delivery',
            description: 'We enhance pay on delivery by offering secure payment processing and escrow services, ensuring funds are  released once delivery is confirmed. ',
        },
    ]

  return (
    <div className='flex justify-center items-center mt-[80px] mb-[50px] '>
    <div className='flex flex-col justify-center items-start gap-14 lg:gap-10 w-full lg:w-[960px]'>
<div className='flex flex-col items-start gap-3 px-[20px] lg:w-[550px]'>
    <h2 className='text-primary font-[400]  text-[28px] leading-[35px] lg:text-[32px] lg:leading-[40px] -tracking-[3%]'>Easy and automated deliveries.</h2>
    <p className='text-secondary font-[400] text-[18px] leading-[27px] -tracking-[3%]'>Don't worry about the logistics behind a sale, we will take care of every step from purchase to delivery.</p>
</div>
<div className='flex flex-col lg:flex-row justify-center items-center gap-5'>
{
    featuresContent.map((feature) => (
        <FeaturesBox key={feature.id} title={feature.title} icon={feature.icon} description={feature.description}/>
    ))
}
</div>
    </div>
    </div>
  )
}

export default Features