import React from "react";
import FAQBox from "./FAQBox";
import { FAQData } from "../../../utils/FAQData";

const FAQ = () => {

  
  return (
    <div className="bg-grey gap-8 lg:gap-3 flex flex-col lg:flex-row justify-center  w-full h-auto py-[100px] px-[20px]">
      <div className="w-full lg:px-[20px] py-[10px]  lg:w-[470px] h-auto">
        <h2 className="text-primary font-[400] text-[32px]  leading-[40px] -tracking-[3%]">FAQs</h2>
      </div>
     
      <div className="flex flex-col gap-8 w-full lg:w-[470px]">
      {FAQData.map((faq) => (
        <FAQBox key={faq.id} question={faq.question} answer={faq.answer} />
      ))}
      </div>
     
    </div>
  );
};

export default FAQ;
