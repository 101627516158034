import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../LandingPage/components/Header';
import Features from '../LandingPage/components/Features';
import SetUp from '../LandingPage/components/SetUp';
import ConnectStore from '../LandingPage/components/ConnectStore';
import FAQ from '../LandingPage/components/FAQ';
import LandingPageLayout from '../../Layouts/LandingLayout';

const Home = () => {
	return (
		<div className='max-w-[100%]'>
			<LandingPageLayout>
				<Helmet>
					<title>
						Ridefraser: Revolutionizing Last Mile Delivery in
						Nigeria
					</title>
					<meta
						name='description'
						content="Ridefraser is a cutting-edge last mile fulfillment operating system addressing Nigeria's fragmented logistics sector. We empower businesses by simplifying delivery operations with real-time tracking and reliable couriers."
					/>
					<meta
						name='keywords'
						content='Ridefraser, Nigerian e-commerce logistics, last mile delivery, real-time delivery tracking, couriers for SMEs, order fulfillment platform'
					/>
					<meta
						property='og:title'
						content='Ridefraser: Revolutionizing Last Mile Delivery in Nigeria'
					/>
					<meta
						property='og:description'
						content="Ridefraser helps businesses connect with reliable couriers, simplify delivery operations, and improve customer satisfaction with real-time tracking across Nigeria's e-commerce landscape."
					/>
					<meta
						property='og:type'
						content='website'
					/>
					<meta
						property='og:url'
						content='https://ridefraser.com'
					/>
					<meta
						property='og:image'
						content='https://ridefraser.com/og-image.jpg'
					/>
					<meta
						name='twitter:card'
						content='summary_large_image'
					/>
					<meta
						name='twitter:title'
						content='Ridefraser: Revolutionizing Last Mile Delivery in Nigeria'
					/>
					<meta
						name='twitter:description'
						content='Ridefraser addresses fragmented logistics in Nigeria by simplifying order fulfillment with real-time tracking and reliable couriers.'
					/>
					<meta
						name='twitter:image'
						content='https://ridefraser.com/twitter-image.jpg'
					/>
					<link
						rel='canonical'
						href='https://ridefraser.com'
					/>
				</Helmet>

				<Header />
				<Features />
				<SetUp />
				<ConnectStore />
				<FAQ />
			</LandingPageLayout>
		</div>
	);
};

export default Home;
