import React from 'react';
import Button from './button';

const ErrorModal = ({ message, onClose, onRetry, open }) => {
	return (
		open && (
			<div
				className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'
				onClick={onClose} // Close the modal on background click
			>
				<div
					className='bg-white p-4 rounded-lg shadow-lg max-w-sm w-full'
					onClick={(e) => e.stopPropagation()} // Prevent closing modal on inner click
				>
					<h2 className='text-[18px] font-semibold mb-1'>
						An Error Occurred
					</h2>
					<p className='text-secondary mb-10'>{message}</p>
					<div className='flex justify-end gap-4'>
						<Button
							type='tertiary'
							label={'Close'}
							size='S'
							onClick={onClose}
						/>
						{onRetry && (
							<Button
								type='primary'
								size='S'
								label={'Retry'}
								onClick={onRetry}
							/>
						)}
					</div>
				</div>
			</div>
		)
	);
};

export default ErrorModal;
