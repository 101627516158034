import { createSlice } from '@reduxjs/toolkit';

const sessionSlice = createSlice({
  name: "session",
  initialState: {
    sessionId: null,
    loading: false,
    error: null,
  },
  reducers: {
    sessionStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    sessionSuccess: (state, action) => {
      state.sessionId = action.payload.sessionId;
      state.loading = false;
    },
    sessionFail: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { sessionStart, sessionSuccess, sessionFail } =
  sessionSlice.actions;

export default sessionSlice.reducer;
