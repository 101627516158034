import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import StoreHeader from '../pages/StoreFront/components/StoreHeader';

const Layout = ({
	children,
	storeData,
	headerType = 'store',
	background = 'bg-white',
}) => {
	useEffect(() => {
		// console.log(storeData);
	}, [storeData]);

	return (
		<div className={`flex ${background} min-h-[100vh]`}>
			<main className='flex-1 overflow-hidden'>
				<Helmet>
					<title>{storeData?.storeName || 'Store Front'}</title>
					<meta
						name='description'
						content={`Welcome to ${storeData?.storeName}`}
					/>
				</Helmet>

				<StoreHeader
					headerType={headerType}
					storeData={storeData}
				/>

				<div
					className={` mx-auto max-w-[1080px] overflow-auto flex justify-center my-20`}>
					{children}
				</div>
				
			</main>
		</div>
	);
};

export default Layout;
