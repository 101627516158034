import { createSlice } from '@reduxjs/toolkit';


const couponSlice = createSlice({
  name: 'coupons',
  initialState: {
    coupons: [],
    selectedCoupon: null,
    loading: false,
    error: null,
  },
  reducers: {
    selectCoupon: (state, action) => {
      state.selectedCoupon = action.payload;
    },
    clearSelectedCoupon: (state) => {
      state.selectedCoupon = null;
    },
    setCoupons: (state, action) => {
        state.coupons = action.payload.coupons;
        state.loading = action.payload.loading;
        state.error = action.payload.error;
      },
      setSelectedCoupon: (state, action) => {
        state.selectedCoupon = action.payload; // Update selectedCoupon
      },
      removeCoupon: (state) => {
        state.selectedCoupon = null; // Clear the selected coupon
      },

  },
 
});

export const { setCoupons, selectCoupon, clearSelectedCoupon, setSelectedCoupon,  removeCoupon, } = couponSlice.actions;
export default couponSlice.reducer;
