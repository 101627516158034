import React from 'react';

const Switch = ({ toggleSwitch, isOn }) => {
	return (
		<button
			onClick={toggleSwitch}
			className={`w-[40px] h-[24px] flex items-center rounded-full p-[2px] transition duration-300 ${
				isOn ? 'bg-primary' : 'bg-[#AAB1AF]'
			}`}>
			<div
				className={`bg-white w-[20px] h-[20px] rounded-full transform transition-transform duration-300 ${
					isOn ? 'translate-x-4' : ''
				}`}></div>
		</button>
	);
};

export default Switch;
