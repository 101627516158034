import React from 'react';
import { Helmet } from 'react-helmet';
import delivery from '../../assets/images/delivery.png';
import { Teamdata } from '../../utils/Teamdata';
import LandingPageLayout from '../../Layouts/LandingLayout';

const About = () => {
	return (
		<LandingPageLayout>
			<Helmet>
				<title>
					About Ridefraser: Transforming E-commerce Logistics in
					Nigeria
				</title>
				<meta
					name='description'
					content='Learn about Ridefraser, a leading platform revolutionizing e-commerce logistics in Nigeria. We provide reliable, affordable, and innovative logistics solutions to empower businesses.'
				/>
				<meta
					name='keywords'
					content='Ridefraser, e-commerce logistics Nigeria, last mile delivery, logistics solutions, Nigerian businesses, courier service'
				/>
				<meta
					property='og:title'
					content='About Ridefraser: Transforming E-commerce Logistics in Nigeria'
				/>
				<meta
					property='og:description'
					content='At Ridefraser, we simplify shipping, empower merchants, and provide seamless logistics solutions tailored to the Nigerian market.'
				/>
				<meta
					property='og:type'
					content='website'
				/>
				<meta
					property='og:url'
					content='https://ridefraser.com/about'
				/>
				<meta
					property='og:image'
					content='https://ridefraser.com/og-about-image.jpg'
				/>
				<link
					rel='canonical'
					href='https://ridefraser.com/about'
				/>
			</Helmet>

			<div className='mb-10 max-w-full'>
				{/* Hero Section */}
				<div className='bg-hero-image bg-neutral'>
					<div className='h-[90vh] flex md:pl-28'>
						<div
							className='flex flex-col items-start justify-center md:w-[50%] gap-4 px-4'
							data-aos='fade-right'>
							<h1 className='md:text-[46px] text-[28px] pt-20 text-primary tracking-tight md:leading-[57px] sm:leading-[35px] font-normal'>
								We're on a mission to transform e-commerce
								logistics in Nigeria.
							</h1>
						</div>
					</div>
				</div>

				{/* Content Section */}
				<div className='w-[80%] mx-auto flex flex-col md:flex-row gap-4 mt-20'>
					<img
						src={delivery}
						alt='Ridefraser delivery service in action'
						className='rounded-lg'
						data-aos='fade-right'
					/>
					<div className='flex flex-col gap-4 text-primary '>
						<p data-aos='fade-up'>
							At RideFraser, we believe that every business
							deserves access to reliable and affordable logistics
							solutions. That's why we created an all-in-one
							platform to simplify shipping, empower merchants,
							and delight customers.
						</p>
						<p data-aos='fade-up'>
							We understand the unique challenges faced by
							Nigerian businesses, and we're committed to
							providing innovative solutions that drive growth and
							success.
						</p>
						<p data-aos='fade-up'>
							Our team is passionate about creating a seamless
							logistics experience that makes it easy for
							businesses to thrive in the digital age.
						</p>
					</div>
				</div>

				{/* Team Section */}
				<div className='w-[80%] text-center mx-auto'>
					<h3
						className='text-[32px] mt-16 text-primary mb-4'
						data-aos='fade-up'>
						The Team
					</h3>
					<p
						className=' md:w-[60%] w-[95%] mx-auto text-secondary'
						data-aos='fade-up'>
						Meet the passionate team behind RideFraser. We're a
						diverse group of problem solvers, innovators, and
						logistics enthusiasts who are dedicated to making a
						difference in the Nigerian e-commerce landscape.
					</p>
					<div
						className='grid md:grid-cols-3 md:gap-12 gap-8 grid-cols-2 mt-12'
						data-aos='flip-right'>
						{Teamdata.map((team, index) => (
							<div
								key={index}
								className='flex flex-col gap-2'
								data-aos='flip-right'>
								<img
									src={team.img}
									alt={`${team.name} - ${team.role}`}
									className='rounded-lg'
								/>
								<p className='text-primary text-left md:text-[20px] tracking-tight '>
									{team.name}
								</p>
								<p className='text-secondary text-left text-[13px]'>
									{team.role}
								</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</LandingPageLayout>
	);
};

export default About;
