import React, { useState } from 'react';
import InputField from './input_field';
import Button from './button';
import ModalHeader from './modalHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useAPI } from '../../../api/api_context';
import { addCustomerDetails } from '../../../store/customerDetailsSlice';
import { toast } from 'sonner';

const DeliveryDetails = ({ setIsDetailsModalOpen }) => {
	const apiservice = useAPI();
	const dispatch = useDispatch();
	const { userDetails } = useSelector((state) => state.customer);

	const [name, setName] = useState(userDetails?.name || '');
	const [email, setEmail] = useState(userDetails?.email || '');
	const [phone, setPhone] = useState(userDetails?.phone_number || '');

	const handleSave = async () => {
		try {
			const response = await apiservice.addCustomerDetails(
				name,
				email,
				phone,
			);
console.log('Response', response);
			dispatch(addCustomerDetails({
				name: response.name,
				email: response.email,
				phone_number: response.phone_number,
			}));
			toast.success('Details added successfully');
			setIsDetailsModalOpen(false);
		} catch (error) {
			toast.error('Error adding details');
			console.error('Error:', error);
		}
	}

	
	

	return (
		<div
			className='fixed inset-0 bg-primary bg-opacity-50 z-50 flex items-center justify-center modal-overlay'
			onClick={() => setIsDetailsModalOpen(false)}>
			<div
				onClick={(e) => e.stopPropagation()}
				className='bg-white rounded-lg max-w-md w-full mx-4 relative'>
				<ModalHeader
					header='Your details'
					onClick={() => setIsDetailsModalOpen(false)}
				/>
				<div className='flex flex-col gap-4 w-full p-4'>
					<InputField
						label='Full Name'
						value={name}
						handleValue={setName}
						placeholder='ex. Andre Smith'
						name='fullname'
						type='text'
					/>

					<InputField
						label='Email Address'
						value={email}
						handleValue={setEmail}
						placeholder='ex. andre@smith.co'
						name='email'
						type='email'
					/>
					<InputField
						label='Phone Number'
						value={phone}
						handleValue={setPhone}
						placeholder='ex. 08000000000'
						name='phone'
						type='number'
					/>
					<Button
						type='primary'
						label={'Done'}
						className='w-full '
						borderRadius='rounded-[100px]'
						onClick={handleSave}
					/>
				</div>
			</div>
		</div>
	);
};

export default DeliveryDetails;
