import React from 'react';
import { ProductData } from '../../../utils/BlogData';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ProductBox from './ProductBox';
import useDisplayProduct from '../../../custom-hooks/useDisplayProduct';
import useBlogFind from '../../../custom-hooks/useBlogFind';
import useProductFind from '../../../custom-hooks/useProductFind';

const LazyBlogImage = ({ src, alt }) => {
	return (
		<LazyLoadImage
			alt={alt}
			src={src}
			className='w-full object-cover h-[310px] rounded-[8px]'
			effect='blur'
		/>
	);
};

const BlogDetail = () => {
	const { displayProductCount, handleProductViewMore } = useDisplayProduct();
	let { blog, blogFormatInfo } = useBlogFind();
	let { product, productFormatInfo } = useProductFind();

	if (!blog && !product) {
		return (
			<div className='mt-[90px] flex justify-center mb-[40px] w-full'>
				Blog not found
			</div>
		);
	}

	if (blog) {
		return (
			<div className='mt-[120px] flex justify-center mb-[40px] w-full px-[20px]'>
				<div className='flex flex-col gap-5 w-full lg:w-[900px] h-auto  px-[10px] py-[20px]'>
					<div className=' flex flex-col gap-6'>
						<h1 className='text-primary text-[41px] font-[400] leading-[52px] -tracking-[3%] '>
							{blog.title}
						</h1>
						<div className='flex gap-3'>
							<p className='text-secondary text-[13px] font-[400] leading-[21px] -tracking-[3%]'>
								{blog.date}
							</p>
							<p className='text-secondary text-[13px] font-[400] leading-[21px] -tracking-[3%]'>
								By {blog.author}
							</p>
						</div>
					</div>
					<div className='flex flex-col gap-8'>
						<LazyBlogImage
							src={blog.image}
							alt={blog.title}
						/>

						<p
							className='text-black  font-[400] leading-[24px] -tracking-[3%]'
							dangerouslySetInnerHTML={{
								__html: blogFormatInfo,
							}}></p>
					</div>

					<h1 className='text-primary text-[24px] font-[400] leading-[30px] px-[20px]  -tracking-[3%] mt-[50px] mb-[0px]'>
						Read More
					</h1>
					<div className='flex flex-col md:flex-row justify-center items-center flex-wrap gap-5'>
						{ProductData.slice(0, displayProductCount).map(
							(product) => (
								<ProductBox
									key={product.id}
									id={product.id}
									title={product.title}
									image={product.image}
									date={product.date}
									info={product.info}
								/>
							),
						)}
					</div>
					{displayProductCount < ProductData.length && (
						<button
							className='text-basegreen text-center underline underline-offset-4  font-[400] leading-[24px] -tracking-[3%] mt-[30px] mb-[40px]'
							onClick={handleProductViewMore}>
							View more
						</button>
					)}
				</div>
			</div>
		);
	}

	if (product) {
		return (
			<div className='mt-[120px] flex justify-center mb-[40px] w-full'>
				<div className='flex flex-col gap-5 w-full lg:w-[900px] h-auto  px-[10px] py-[20px]'>
					<div className=' flex flex-col gap-6'>
						<h1 className='text-primary text-[41px] font-[400] leading-[52px] -tracking-[3%] '>
							{product.title}
						</h1>
						<div className='flex gap-3'>
							<p className='text-secondary text-[13px] font-[400] leading-[21px] -tracking-[3%]'>
								{product.date}
							</p>
							<p className='text-secondary text-[13px] font-[400] leading-[21px] -tracking-[3%]'>
								By {product.author}
							</p>
						</div>
					</div>
					<div className='flex flex-col gap-8'>
						<LazyBlogImage
							src={product.image}
							alt={product.title}
						/>

						<p
							className='text-black  font-[400] leading-[24px] -tracking-[3%]'
							dangerouslySetInnerHTML={{
								__html: productFormatInfo,
							}}></p>
					</div>
					<h1 className='text-primary text-[24px] font-[400] leading-[30px] px-[20px] lg:px-[10px] -tracking-[3%] mt-[50px] mb-[0px]'>
						Read More
					</h1>
					<div className='flex flex-col md:flex-row justify-center items-center flex-wrap gap-5'>
						{ProductData.slice(0, displayProductCount).map(
							(product) => (
								<ProductBox
									key={product.id}
									id={product.id}
									title={product.title}
									image={product.image}
									date={product.date}
									info={product.info}
								/>
							),
						)}
					</div>
					{displayProductCount < ProductData.length && (
						<button
							className='text-basegreen text-center underline underline-offset-4  font-[400] leading-[24px] -tracking-[3%] mt-[30px] mb-[40px]'
							onClick={handleProductViewMore}>
							View more
						</button>
					)}
				</div>
			</div>
		);
	}
};
export default BlogDetail;
