import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
	name: 'cart',
	initialState: {
		products: [],
		totalCartValue: 0,
		serviceCharge: 0,
		_id: null,
		isCartOpen: false,
	},
	reducers: {
		cartOpen: (state, action) => {
			state.isCartOpen = action.payload;
		},

		setCart: (state, action) => {
			const { products, totalCartValue, serviceCharge, _id } =
				action.payload;
			state.products = products;
			state.totalCartValue = totalCartValue;
			state.serviceCharge = serviceCharge;
			state._id = _id || null;
		},

		addProductToCart: (state, action) => {
			const product = action.payload;

			const existingProduct = state.products.find(
				(item) => item._id === product._id,
			);

			if (existingProduct) {
				existingProduct.quantity += product.quantity;
				existingProduct.total += product.price * product.quantity;
			} else {
				state.products.push({
					...product,
					total: product.price * product.quantity,
				});
			}

			state.totalCartValue = state.products.reduce(
				(sum, item) => sum + item.total,
				0,
			);
		},

		updateProductQuantity: (state, action) => {
			const { productId, quantity } = action.payload;

			const product = state.products.find(
				(item) => item._id === productId,
			);
			if (product) {
				product.quantity = quantity;
				product.total = product.price * quantity;

				state.totalCartValue = state.products.reduce(
					(sum, item) => sum + item.total,
					0,
				);
			}
		},

		removeProductFromCart: (state, action) => {
			const productId = action.payload;

			state.products = state.products.filter(
				(item) => item._id !== productId,
			);

			state.totalCartValue = state.products.reduce(
				(sum, item) => sum + item.total,
				0,
			);
		},

		clearCart: (state) => {
			state.products = [];
			state.totalCartValue = 0;
			state.serviceCharge = 0;
			state._id = null;
		},
	},
});

export const {
	setCart,
	addProductToCart,
	updateProductQuantity,
	removeProductFromCart,
	clearCart,
	cartOpen,
} = cartSlice.actions;

export default cartSlice.reducer;
