import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../pages/LandingPage/components/Footer';
import Navbar from '../pages/LandingPage/components/Navbar';

const LandingPageLayout = ({ children, title, description }) => {
	return (
		<div className='flex min-h-screen overflow-hidden'>
			<Helmet>
				<title>{title || 'Landing Page'}</title>
				<meta
					name='description'
					content={description || 'Landing Page'}
				/>
			</Helmet>
			<main className='flex-1 bg-[#ffffff] overflow-hidden'>
				<Navbar />
				<div className='flex justify-center'>
					<div className='w-full max-w-[3072px]'>{children}</div>
				</div>
				<Footer />
			</main>
		</div>
	);
};

export default LandingPageLayout;
