import React from 'react';

const SectionHeader = ({
	title,
	onSeeAll,
	showAllCount,
	onScrollLeft,
	onScrollRight,
}) => (
	<div className='flex justify-between items-center '>
		<p className='text-[21px] font-semibold'>{title}</p>
		{showAllCount > 3 && (
			<div className='flex items-center gap-4'>
				{onSeeAll && (
					<button
						onClick={onSeeAll}
						className='text-basegreen font-semibold'>
						{`See All (${showAllCount})`}
					</button>
				)}
				<div className='flex gap-2 items-center'>
					{onScrollLeft && (
						<button
							onClick={onScrollLeft}
							className='p-2 rounded-full bg-gray-100 transition-colors'>
							<i className='fi fi-rr-arrow-small-left h-[18px] w-[18px] flex items-center justify-center'></i>
						</button>
					)}
					{onScrollRight && (
						<button
							onClick={onScrollRight}
							className='p-2 rounded-full bg-gray-100 transition-colors'>
							<i className='fi fi-rr-arrow-small-right h-[18px] w-[18px] flex items-center justify-center'></i>
						</button>
					)}
				</div>
			</div>
		)}
	</div>
);

export default SectionHeader;
