import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../Layouts/StoreLayout';
import { FaPlus, FaMinus } from 'react-icons/fa6';
import { toast } from 'sonner';
import { FaChevronRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { setCart } from '../../store/cartSlice';
import { useAPI } from '../../api/api_context';
import Button from './components/button';
import { IoArrowBack } from 'react-icons/io5';
import { RiShare2Line } from 'react-icons/ri';
import { numberWithCommas } from './utils/add_commas';

const ProductDetails = () => {
	const { storeIdentifier, productId } = useParams();
	const storeData = useSelector((state) => state.store);
	// console.log(storeIdentifier);
	// console.log(productId);

	const apiservice = useAPI();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [selectedColor, setSelectedColor] = useState(null);
	const [selectedSize, setSelectedSize] = useState(null);
	const [selectedImage, setSelectedImage] = useState(null);
	const [colorNames, setColorNames] = useState({});
	const [quantity, setQuantity] = useState(1);
	const [selectedAttributes, setSelectedAttributes] = useState({
		color: null,
		size: null,
	});

	const [product, setProduct] = useState(null);
	const [loadingProduct, setLoadingProduct] = useState(true);

	useEffect(() => {
		const fetchProductDetails = async () => {
			try {
				setLoadingProduct(true);
				const response = await apiservice.getProduct(
					storeIdentifier,
					productId,
				);
				setProduct(response);
				setSelectedImage(response?.images[0]);
				console.log('Product Details:', response);
			} catch (error) {
				console.error('Error fetching product details:', error);
			} finally {
				setLoadingProduct(false);
			}
		};

		fetchProductDetails();
	}, [apiservice, productId, storeIdentifier]);

	const fetchColorName = async (colorHex) => {
		try {
			const response = await fetch(
				`https://api.color.pizza/v1/?values=${encodeURIComponent(
					colorHex.replace('#', ''),
				)}`,
			);
			const data = await response.json();
			const colorData = data.colors[0];
			setColorNames((prevColorNames) => ({
				...prevColorNames,
				[colorHex]: colorData.name,
			}));
		} catch (error) {
			console.error('Error fetching color name:', error);
		}
	};
	useEffect(() => {
		if (selectedColor && selectedColor.startsWith('#')) {
			if (colorNames[selectedColor]) {
				// setSelectedColorName(colorNames[selectedColor]);
			} else {
				fetchColorName(selectedColor);
			}
		}
	}, [selectedColor, colorNames]);

	useEffect(() => {
		if (product?.attributes) {
			const { type, clothing, footwear } = product.attributes;

			let colors = [];
			if (type === 'Clothing' && clothing?.colors) {
				colors = clothing.colors;
			} else if (type === 'Footwear' && footwear?.colors) {
				colors =
					typeof footwear.colors === 'string'
						? footwear.colors
								.split(',')
								.map((color) => color.trim())
						: footwear.colors;
			}

			colors.forEach((color) => {
				if (!colorNames[color] && color.startsWith('#')) {
					fetchColorName(color);
				}
			});
		}
	}, [product, colorNames]);

	const handleColorSelect = (color) => {
		setSelectedColor(color);
		const colorName = colorNames[color] || 'Loading...';
		// setSelectedColorName(colorName);

		// Update attributes with new color
		setSelectedAttributes((prev) => ({
			...prev,
			color: colorName,
		}));
	};

	const handleSizeSelect = (size) => {
		setSelectedSize(size);

		// Update attributes with new size
		setSelectedAttributes((prev) => ({
			...prev,
			size: size,
		}));
	};

	const handleAddToCart = async () => {
		const clothingAttributes = product.attributes?.clothing || {};
		const footwearAttributes = product.attributes?.footwear || {};
	
		// Determine which attributes to use (e.g., prioritize clothing, then footwear)
		const activeAttributes = clothingAttributes.colors?.length > 0 || clothingAttributes.sizes?.length > 0
			? clothingAttributes
			: footwearAttributes;
	
		// Check if the product has color and size attributes
		const hasColors = Array.isArray(activeAttributes?.colors) && activeAttributes.colors.length > 0;
		const hasSizes = Array.isArray(activeAttributes?.sizes) && activeAttributes.sizes.length > 0;
	
	console.log({ hasColors, hasSizes, selectedColor, selectedSize });
	if ((hasColors && !selectedColor) || (hasSizes && !selectedSize)) {
        return toast('Please select a color and size.');
    }
		try {
			const attributes = {
				color: selectedColor,
				size: selectedSize,
			};

			const response = await apiservice.addToCart(
				product._id,
				quantity,
				attributes,
			);
			console.log(response.data);
			if (response.status === 200) {
				// console.log(response.status);
				toast('Added to cart successfully');
				console.log(response);
				dispatch(
					setCart({
						products: response.data.cart.products,
						totalCartValue: response.data.cart.totalCartValue,
						serviceCharge: response.data.cart.serviceCharge,
						_id: response.data.cart._id,
					}),
				);
			}
		} catch (error) {
			toast('Failed to add to cart');
		}
	};

	const handleIncrement = () => {
		const newQty = quantity + 1;
		setQuantity(newQty);
	};

	const handleDecrement = () => {
		if (quantity > 1) {
			const newQty = quantity - 1;
			setQuantity(newQty);
		}
	};

	const handleBackToHome = () => {
		const currentPath = window.location.pathname;
		const targetPath = `/${storeIdentifier}`;

		if (currentPath !== targetPath) {
			navigate(targetPath);
		}
	};

	const handleShareProduct = () => {};

	useEffect(() => {
		if (product) {
			setSelectedImage(product.images[0]);
		}
	}, [product]);

	const handleImageClick = (image) => {
		setSelectedImage(image);
	};

	const renderSizes = (sizes) => {
		if (!sizes) return null;

		const sizeArray = Array.isArray(sizes)
			? sizes
			: sizes.split(',').map((size) => size.trim());

		return (
			<div className='w-full flex flex-wrap gap-3 text-[15px]'>
				{sizeArray.map((size) => (
					<div
						key={size}
						className={`bg-gray-100 px-6 py-1.5 rounded-full w-fit cursor-pointer ${
							selectedSize === size
								? 'bg-[#E6F7F7] border-[0.5px] border-[#28A745] text-[#28A745]'
								: ''
						}`}
						onClick={() => handleSizeSelect(size)}>
						{size}
					</div>
				))}
			</div>
		);
	};

	const renderColors = (colors) => {
		if (!colors) return null;

		const colorArray = Array.isArray(colors)
			? colors
			: colors.split(',').map((color) => color.trim());

		return (
			<div className='w-full flex flex-wrap gap-3 text-[15px]'>
				{colorArray.map((color) => (
					<div
						key={color}
						className={`flex items-center gap-2 bg-gray-100 pr-4 pl-1.5 py-1.5 rounded-full w-fit cursor-pointer ${
							selectedColor === color
								? 'bg-[#E6F7F7] border-[0.5px] border-[#28A745] text-[#28A745]'
								: ''
						}`}
						onClick={() => handleColorSelect(color)}>
						<div
							className={`h-6 w-6 rounded-full border ${
								selectedColor === color
									? 'border-[#28A745]'
									: ''
							}`}
							style={{
								backgroundColor: color.startsWith('#')
									? color
									: '#' + color,
							}}
						/>
						<div
							className={
								selectedColor === color ? 'text-[#28A745]' : ''
							}>
							{color.startsWith('#')
								? colorNames[color] || 'Loading...'
								: color}
						</div>
					</div>
				))}
			</div>
		);
	};

	const renderAttributes = () => {
		if (!product?.attributes) return null;

		const { type, clothing, footwear, electronics } = product.attributes;

		switch (type) {
			case 'Clothing':
				return (
					<div className='flex flex-col gap-5'>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px] font-light'>
								Available Sizes
							</div>
							<div className='text-primary'>
								{renderSizes(clothing.sizes)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px] font-light'>
								Available Colors
							</div>
							<div className='text-primary'>
								{renderColors(clothing.colors)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px] font-light'>
								Gender
							</div>
							<div className='text-primary'>
								{clothing.gender}
							</div>
						</div>
					</div>
				);

			case 'Footwear':
				return (
					<div className='flex flex-col gap-6'>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px]'>
								Available Sizes
							</div>
							<div className='text-primary'>
								{renderSizes(footwear.sizes)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px]'>
								Available Colors
							</div>
							<div className='text-primary'>
								{renderColors(footwear.colors)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px]'>
								Brand
							</div>
							<div className='text-primary'>{footwear.brand}</div>
						</div>
					</div>
				);

			case 'Electronics':
				return (
					<div className='flex flex-col gap-6'>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[13px]'>
								Type
							</div>
							<div className='text-primary'>
								{electronics.type}
							</div>
						</div>
						{electronics.type === 'laptop' && (
							<>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[13px]'>
										RAM
									</div>
									<div className='text-primary'>
										{electronics.laptops.ram} GB
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[13px]'>
										Processor
									</div>
									<div className='text-primary'>
										{electronics.laptops.processor}
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[13px]'>
										Storage
									</div>
									<div className='text-primary'>
										{electronics.laptops.storage} GB
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[13px]'>
										Operating System
									</div>
									<div className='text-primary'>
										{electronics.laptops.os}
									</div>
								</div>
							</>
						)}
					</div>
				);

			default:
				return null;
		}
	};

	if (loadingProduct) {
		return (
			<div className='flex items-center justify-center h-screen'>
				<div className='text-xl text-gray-600'>Loading...</div>
			</div>
		);
	}

	if (!product) {
		return (
			<div className='flex items-center justify-center h-screen'>
				<div className='text-xl text-gray-600'>
					Product not found or unavailable.
				</div>
			</div>
		);
	}

	return (
		<Layout storeData={storeData}>
			<div className='flex flex-col gap-6 px-2'>
				<div className='flex justify-between items-center w-full mt-6'>
					<div className='flex items-center gap-2 md:gap-8'>
						<button onClick={handleBackToHome}>
							<IoArrowBack size={21} />
						</button>
						<div className='flex items-center gap-2'>
							<h2 className='cursor-pointer  leading-[18px] text-secondary'>
								Products
							</h2>
							<button className='text-secondary'>
								<FaChevronRight size={12} />
							</button>
							<h2 className=' leading-[18px] text-primary'>
								{product?.name}
							</h2>
						</div>
					</div>
					<div className='flex space-x-6'>
						<div className='flex items-center gap-4'>
							<button onClick={handleShareProduct}>
								<div className='flex text-secondary items-center space-x-3'>
									<RiShare2Line size={24} />
									<span>Share</span>
								</div>
							</button>
						</div>
					</div>
				</div>
				<div className='pt-0 '>
					<div className='md:grid grid-cols-2 gap-8'>
						<div className='flex flex-col space-y-4 w-full '>
							<img
								src={selectedImage}
								alt={`Product ${product?.name}`}
								className='rounded-[12px] w-full h-[30vh] md:h-[65vh] object-cover border border-gray-300'
							/>
							<div className='w-full flex space-x-4 items-center overflow-x-auto pb-2'>
								{product?.images?.map((image, index) => (
									<img
										key={index}
										src={image}
										alt={`Product ${index + 1}`}
										onClick={() => handleImageClick(image)}
										className={`h-[109px] w-[109px] rounded-[8px] flex-shrink-0 cursor-pointer ${
											selectedImage === image
												? 'border-2 border-primary'
												: 'border border-gray-300'
										}`}
									/>
								))}
							</div>
						</div>

						{/* Product Details */}
						<div className='flex flex-col items-start px-2'>
							<div className='flex flex-col space-y-4'>
								<h1 className='md:text-[40px] text-lg leading-[46px] text-primary'>
									{product?.name}
								</h1>
								{(selectedAttributes.color ||
									selectedAttributes.size) && (
									<div className='flex flex-col gap-2 '>
										<h2 className='text-[14px] text-secondary'>
											Selections
										</h2>

										<div className='bg-neutral border border-outline/50 w-full mb-2 p-3 rounded-lg'>
											<p>
												{selectedAttributes.color &&
													`Color: ${selectedAttributes.color}`}
												{selectedAttributes.color &&
													selectedAttributes.size &&
													' | '}
												{selectedAttributes.size &&
													`Size: ${selectedAttributes.size}`}
											</p>
										</div>
									</div>
								)}
								<div className='flex flex-col gap-2 '>
									<h2 className='text-[14px] text-secondary'>
										Product Price
									</h2>
									<p className='text-[21px] font-medium text-primary'>
										NGN{' '}
										{numberWithCommas(product?.unitPrice)}
									</p>
								</div>
								<div className='flex flex-col gap-2 '>
									<h2 className='text-[14px] leading-[18px] text-secondary'>
										Product Description
									</h2>
									<p className='text-[16px] text-primary font-light'>
										{product?.description}
									</p>
								</div>

								<div className='flex flex-col gap-2'>
									<h2 className='text-[14px] text-secondary'>
										Available Quantity
									</h2>
									<p className='text-[18px] leading-[21px] text-primary'>
										{product?.availableQuantity} Units
									</p>
								</div>
								{renderAttributes()}
							</div>

							<div className='w-full mt-6'>
								<div className='border-t pt-4 flex justify-between w-full items-center gap-4 '>
									<Button
										type='primary'
										label={`Add ${quantity} to Cart`}
										onClick={() => handleAddToCart()}
										className='rounded-full w-full text-[15px]'
										size='M'
									/>
									<div className='flex items-center gap-2'>
										<button
											onClick={handleDecrement}
											className='w-10 h-10 cursor-pointer bg-formBG flex items-center justify-center border rounded-full text-lg text-gray-700 hover:bg-gray-100 focus:outline-none'
											disabled={quantity <= 1}>
											<FaMinus />
										</button>

										<div
											onClick={handleIncrement}
											className='w-10 h-10 cursor-pointer bg-formBG flex items-center justify-center border rounded-full text-lg text-gray-700 hover:bg-gray-100 focus:outline-none'>
											<FaPlus />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ProductDetails;
