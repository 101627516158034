import { createSlice } from '@reduxjs/toolkit';

const locationSlice = createSlice({
	name: 'location',
	initialState: {
		coordinates: { latitude: 0, longitude: 0 },
		userLocation: '',
		deliveryCost: 0,
		eta: 0,
		loading: false,
		error: null,
	},
	reducers: {
		setCoordinates: (state, action) => {
			const { latitude, longitude } = action.payload;
			state.coordinates = { latitude, longitude };
		},
		setUserLocation: (state, action) => {
			state.userLocation = action.payload;
		},
		setDeliveryCost: (state, action) => {
			state.deliveryCost = action.payload;
		},
		setETA: (state, action) => {
			state.eta = action.payload;
		},
	},
});

export const {setCoordinates, setUserLocation, setDeliveryCost, setETA } =
	locationSlice.actions;
export default locationSlice.reducer;
