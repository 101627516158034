import React from 'react';
import icon1 from '../../../assets/images/icon1.png';
import icon2 from '../../../assets/images/icon2.png';
import icon3 from '../../../assets/images/icon3.png';
import Card from './EventCard';
// import { useSelector } from 'react-redux';
import ArrowRight from '../../../assets/images/arrow-right.svg';

const Information = () => {
	const data = [
		{
			icon: icon1,
			title: 'Connect and Collaborate',
			text: 'Join a vibrant community of like-minded entrepreneurs and business owners. Share insights, exchange ideas, and collaborate on projects to accelerate your growth.',
		},
		{
			icon: icon2,
			title: 'Learn and Grow',
			text: 'Join a vibrant community of like-minded entrepreneurs and business owners. Share insights, exchange ideas, and collaborate on projects to accelerate your growth.',
		},
		{
			icon: icon3,
			title: 'Simplify and Automate',
			text: 'Join a vibrant community of like-minded entrepreneurs and business owners. Share insights, exchange ideas, and collaborate on projects to accelerate your growth.',
		},
	];
	// const loading = useSelector((state) => state.slice.registerEventStatus);

	return (
		<div>
			<div className='w-[80%] mx-auto pt-20'>
				<div
					className='flex flex-col gap-4 justify-center'
					data-aos='fade-right'>
					<h3 className='font-semibold text-[32px]  text-primary'>
						Grow your business together
					</h3>
					<p className='text-secondary md:w-[60%] text-[18px] tracking-wider'>
						Join a network of ambitious entrepreneurs and business
						leaders who are passionate about simplifying and scaling
						their operations.
					</p>
				</div>
				<div className='flex flex-col md:flex-row gap-4'>
					{data.map((item, index) => (
						<div
							key={index}
							className='bg-grey py-8 px-6 mt-8 rounded-[12px]'
							data-aos='zoom-out'>
							<img
								src={item.icon}
								alt='icon'
								className='mb-8'
							/>
							<h3 className='text-primary text-[20px] mb-4'>
								{item.title}
							</h3>
							<p className='text-secondary '>{item.text}</p>
						</div>
					))}
				</div>
				<div className='mt-10 font-bold'>
					<h3 className='text-center text-primary text-[32px] mt-24 mb-8 '>
						Upcoming Events
					</h3>
					<div className=''>
						<Card />
					</div>
				</div>
			</div>
			<div className='flex flex-col gap-10 items-center justify-center h-[70vh] mt-24 bg-basegreen '>
				<div className='border rounded-[32px] w-fit px-4 py-2 border-textgreen'>
					<p className='text-textgreen'>We are in BETA</p>
				</div>
				<p className='text-grey md:w-[50%] w-[80%] text-center text-2xl font-normal'>
					As a community member, be among the first to experience our
					platform before its public launch.
				</p>
				<div className='flex text-grey gap-2'>
					<p>Join the Community</p>
					<img
						src={ArrowRight}
						alt='arrow icon'
					/>
				</div>
			</div>
		</div>
	);
};

export default Information;
