import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderUsesPIN: true,
  orderUsesEscrow: true,
  deliveryTiming: "",
  scheduledDateTime: "",
};

const orderSettingSlice = createSlice({
  name: "orderSettings",
  initialState,
  reducers: {
    setOrderSettings: (state, action) => {
      const { orderUsesPIN, orderUsesEscrow } = action.payload;
      if (orderUsesPIN !== undefined) state.orderUsesPIN = orderUsesPIN;
      if (orderUsesEscrow !== undefined) state.orderUsesEscrow = orderUsesEscrow;
    },
    // setOrderUsesPIN: (state, action) => {
    //   state.orderUsesPIN = action.payload;
    // },
    // setOrderUsesEscrow: (state, action) => {
    //   state.orderUsesEscrow = action.payload;
    // },
    setDeliveryTiming: (state, action) => {
      state.deliveryTiming = action.payload;
    },
    setScheduledDateTime: (state, action) => {
      state.scheduledDateTime = action.payload;
    },
  },
});

export const {
  setOrderUsesPIN,
  setOrderUsesEscrow,
  setDeliveryTiming,
  setScheduledDateTime,
  setOrderSettings
} = orderSettingSlice.actions;

export default orderSettingSlice.reducer;
