import React from "react";
import Setup from "../../../assets/images/setupImage.png";
import StepsBox from "./StepsBox";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazySetupImage = ({ src, alt }) => {
  return (
    <LazyLoadImage
      alt={alt}
      src={src}
      effect="blur"
      className=" w-[340px] h-[370px] lg:w-[468px] lg:h-[560px]"
    />
  );
};

const SetUp = () => {
  const stepsContent = [
    {
      id: 1,
      title: "Connect Your Store",
      description:
        "Create a RideFraser store or link your online store or social media shop to RideFraser in a few clicks. We integrate seamlessly via APIs with any ecommerce store.",
    },
    {
      id: 2,
      title: "Set Your Preferences",
      description:
        "Tell us your delivery needs – from preferred courier partners to pricing options. We'll customize your experience to match your business requirements.",
    },
    {
      id: 3,
      title: "Sit Back & Relax",
      description:
        "Once you're set up, RideFraser takes the wheel. We'll automatically handle everything from finding the right courier to tracking your shipments and managing returns.",
    },
  ];
  return (
    <>
      {/* Border */}
      <div className="flex justify-center items-center  mt-[80px] ">
        <div className="border border-[#C8C8C8] w-[90%] lg:w-[960px]"></div>
      </div>
      {/* Set up */}
      <div className="flex  justify-center items-center w-full mt-[80px] mb-[50px] ">
        <div className="flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-6">
          <div data-aos="zoom-in">
            <LazySetupImage src={Setup} alt="Set up background illustration" />
          </div>

          <div className="flex flex-col items-start gap-10 lg:gap-16 w-full px-[20px] lg:px-0 lg:w-[468px]">
            <h1 className="text-primary font-[400]  text-[28px] leading-[35px] lg:text-[32px] lg:leading-[40px] -tracking-[3%]">
              Set Up & Automate Deliveries with RideFraser: 3 Easy Steps
            </h1>
            {/* Steps */}
            <div className="flex flex-col gap-10 lg:gap-7 items-start">
              {stepsContent.map((steps) => (
                <StepsBox
                  key={steps.id}
                  title={steps.title}
                  description={steps.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetUp;
