import React from 'react';

const Button = ({
	type = 'primary',
	label,
	onClick,
	iconLeft,
	iconRight,
	disabled = false,
	loading = false,
	className = '',
	trackLabel,
	size = 'M',
	borderRadius = 'rounded-full',
}) => {
	const baseStyles =
		'flex items-center justify-center font-medium transition-all duration-300 ease-in-out focus:outline-none rounded-full cursor-pointer';

	const sizeStyles = {
		S: 'h-10 px-4 text-sm',
		M: 'h-12 px-6 text-base',
		L: 'h-14 px-8 text-lg',
	};

	const primaryStyles = `bg-[#0B6E4F] min-w-[120px] text-white disabled:text-gray-500 hover:bg-[#0B6E4FE5] disabled:bg-gray-100 ${borderRadius}`;

	const secondaryStyles = `bg-transparent font-medium border border-[#0B6E4F] text-[#0B6E4F] hover:text-[#0B6E4FE5] hover:border-[#0B6E4FE5] disabled:text-gray-500 disabled:bg-gray-100 tracking-tight ${borderRadius}`;

	const tertiaryStyles = `text-basegreen underline font-medium disabled:text-gray-500 ${borderRadius}`;

	const promotionStyles = `w-[81px] h-[56px] py-[12px] px-[18px]  leading-[20px] -tracking-[3%] font-[400] flex items-center justify-center border border-[#CECECE] bg-[#FAFAFA] text-[#AAB1AF] ${borderRadius}`;

	const buttonStyles =
		type === 'primary'
			? primaryStyles
			: type === 'secondary'
			? secondaryStyles
			: type === 'promotion'
			? promotionStyles
			: tertiaryStyles;

	const finalButtonStyles = `${baseStyles} ${
		sizeStyles[size] || sizeStyles.M
	} ${buttonStyles}`;

	const handleClick = (event) => {
		if (disabled || loading) return;

		if (window.gtag) {
			window.gtag('event', 'button_click', {
				event_category: 'Button',
				event_label: trackLabel || label,
				value: 1,
			});
		}

		if (onClick) {
			onClick(event);
		}
	};

	return (
		<button
			onClick={handleClick}
			disabled={disabled || loading}
			className={`${finalButtonStyles} ${className}`}>
			{loading ? (
				<div className='spinner w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin'></div>
			) : (
				<>
					{iconLeft && <span className='mr-2'>{iconLeft}</span>}
					{label}
					{iconRight && <span className='ml-2'>{iconRight}</span>}
				</>
			)}
		</button>
	);
};

export default Button;
